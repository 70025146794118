import { observer } from 'mobx-react'
import { useStore } from '../../models/Store'
import NoWithdrawAccountsFound from './NoWithdrawAccountsFound/NoWithdrawAccountsFound'
import SavedWithdrawAccounts from './SavedWithdrawAccounts/SavedWithdrawAccounts'

const FiatWithdrawForm = observer(() => {
  const { fiatStore } = useStore()

  if (!fiatStore.withdrawReady) return <>Loading...</>
  if (fiatStore.withdrawError) return <>Error occurred</>
  if (fiatStore.savedWithdrawAccounts.length === 0)
    return <NoWithdrawAccountsFound />

  return <SavedWithdrawAccounts />
})

export default FiatWithdrawForm
