import type Store from './Store'
import { makeAutoObservable, observable, runInAction, toJS } from 'mobx'
// Expecting an array of tuples for filters to work
type FilterArrayToObject<GivenArray extends readonly string[]> = {
  [Index in GivenArray[number]]: string
}
class FiltersStore<T extends readonly string[]> {
  constructor(private readonly rootStore: Store, filters: string[]) {
    this.rootStore = rootStore
    makeAutoObservable<FiltersStore<any>, 'rootStore'>(this, {
      rootStore: false,
      filters: observable.shallow
    })
    this.filters = {} as FilterArrayToObject<T>
    this.generateFilterFields(filters)
  }

  filters: FilterArrayToObject<T>

  private generateFilterFields(filters: string[]): void {
    filters.forEach((i, index) => {
      runInAction(() => {
        this.filters[i as keyof FilterArrayToObject<T>] = ''
      })
    })
  }

  public setSearchFields(fields: TFieldValues[]): any {
    fields.forEach((f) => {
      this.filters[f.field as keyof FilterArrayToObject<T>] = f.value.toString()
    })
  }

  public resetFilters(): void {
    this.filters = {} as FilterArrayToObject<T>
  }

  public get activeFilterCount(): number {
    return Object.keys(this.searchString).length
  }

  public get searchString(): any {
    const f = toJS(this.filters)

    for (const propName in f) {
      // Supress this issue for now but fix later, this could be a problem
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions, no-prototype-builtins
      if (f.hasOwnProperty(propName)) {
        if (
          f[propName as keyof FilterArrayToObject<T>] === null ||
          f[propName as keyof FilterArrayToObject<T>] === undefined ||
          f[propName as keyof FilterArrayToObject<T>] === ''
        ) {
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
          delete f[propName as keyof FilterArrayToObject<T>]
        }
      }
    }
    return f
  }
}

export type TFieldValues = {
  field: string
  value: string | boolean
}

export default FiltersStore
