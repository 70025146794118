import { type ReactElement, type SVGProps } from 'react'

const Preloader = (
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGElement> => {
  return (
    <svg
      className={'preloader'}
      width="50"
      height="35"
      viewBox="0 0 467 296"
      fill="#F3FF00"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        className={'item-loader'}
        d="M263.358 137.579H389.49C400.066 137.579 405.361 124.794 397.882 117.316L332.039 51.4691C326.868 46.2978 318.295 46.9879 314.018 52.9206L259.045 129.149C256.508 132.667 259.019 137.579 263.358 137.579Z"
      />
      <path
        className={'item-loader'}
        d="M259.044 166.363L314.017 242.59C318.297 248.523 326.867 249.213 332.039 244.042L397.885 178.196C405.363 170.717 400.069 157.932 389.493 157.932H263.357C259.022 157.932 256.507 162.844 259.044 166.363Z"
      />
      <path
        className={'item-loader'}
        d="M177.389 53.1339L228.768 124.379C230.89 127.321 235.274 127.321 237.397 124.379L288.776 53.1339C294.436 45.283 288.828 34.3184 279.146 34.3184H187.015C177.337 34.3184 171.728 45.283 177.389 53.1339Z"
      />
      <path
        className={'item-loader'}
        d="M228.769 171.13L177.39 242.375C171.729 250.226 177.338 261.187 187.02 261.187H279.147C288.829 261.187 294.437 250.226 288.777 242.375L237.398 171.13C235.275 168.188 230.891 168.188 228.769 171.13Z"
      />
      <path
        className={'item-loader'}
        d="M202.804 157.932H76.6716C66.0957 157.932 60.8013 170.717 68.2763 178.196L134.123 244.042C139.294 249.213 147.868 248.523 152.145 242.59L207.117 166.363C209.654 162.844 207.143 157.932 202.804 157.932Z"
      />
      <path
        className={'item-loader'}
        d="M207.116 129.152L152.144 52.9246C147.867 46.9918 139.293 46.3017 134.122 51.473L68.2786 117.319C60.8003 124.798 66.0947 137.583 76.6706 137.583H202.803C207.139 137.583 209.653 132.671 207.116 129.152Z"
      />
      <path d="M151.646 16.959C141.653 16.959 132.26 20.8504 125.196 27.914L25.1631 127.947C19.8719 133.238 16.959 140.273 16.959 147.758C16.959 155.239 19.8719 162.274 25.1631 167.565L125.196 267.598C132.26 274.665 141.653 278.556 151.646 278.556H314.516C324.508 278.556 333.901 274.665 340.968 267.598L441.001 167.565C451.924 156.642 451.924 138.87 441.001 127.947L340.968 27.914C333.901 20.8504 324.508 16.959 314.516 16.959H151.646ZM314.516 295.512H151.646C137.123 295.512 123.473 289.858 113.205 279.59L13.1712 179.557C4.67554 171.064 0 159.769 0 147.758C0 135.743 4.67554 124.451 13.1712 115.955L113.205 15.9221C123.473 5.65732 137.123 0 151.646 0H314.516C329.038 0 342.689 5.65732 352.957 15.9254L452.99 115.959C470.526 133.491 470.526 162.024 452.993 179.557L352.957 279.59C342.689 289.858 329.038 295.512 314.516 295.512Z" />
    </svg>
  )
}

export default Preloader
