import { observer } from 'mobx-react'
import Info from '../../../assets/images/svg/informationlineforheading.svg'
import colors from '../../../assets/scss/themes/colors.module.scss'
import Button from '../../../ui/Button'
import styles from './NoWithdrawAccountsFound.module.scss'
import { useNavigate, useParams } from 'react-router-dom'

const NoWithdrawAccountsFound = observer(() => {
  const navigate = useNavigate()
  const params = useParams()
  return (
    <div className={styles.NoWithdrawAccountsFound}>
      <h5>Select bank account for withdraw</h5>
      <div className={styles.Note}>
        <Info fill={colors.main} color={colors.bg} />
        <p>You don&apos;t have any bank account yet</p>
      </div>
      <Button
        wide
        title={'Add new bank account'}
        onClick={() => {
          navigate(
            `/balances/fiat/new-withdraw-fiat-acc/${params.id as string}`
          )
        }}
      />
    </div>
  )
})

export default NoWithdrawAccountsFound
