import { type ReactElement, type SVGProps } from 'react'

interface InfoIconProps {
  /**
   * specifically color the inner elements of svg
   */
  fillinside?: string
  /**
   * specifically color the background of svg
   */
  fillbackground?: string
}
/**
 * This is a tricky SVG element, please use
 * props fillInside and fillBackground to color the element
 */
const InfoIcon = (
  props: InfoIconProps & SVGProps<SVGSVGElement>
): ReactElement<SVGElement> => {
  return (
    <svg width="25" height="22" viewBox="0 0 25 22" fill="white" {...props}>
      <rect
        y="0.5"
        width="25"
        height="21"
        rx="10.5"
        fill={props.fillbackground ?? 'black'}
      />
      <path
        d="M7.452 17C5.88 15.752 4.896 13.856 4.896 11.42C4.896 8.984 5.88 7.088 7.452 5.84L8.628 6.98C7.284 8.168 6.576 9.548 6.576 11.42C6.576 13.28 7.284 14.684 8.628 15.86L7.452 17ZM11.717 12.596L11.417 6.728H13.445L13.121 12.596H11.717ZM12.425 15.62C11.861 15.62 11.441 15.188 11.441 14.636C11.441 14.048 11.861 13.616 12.425 13.616C13.001 13.616 13.433 14.048 13.433 14.636C13.433 15.188 13.001 15.62 12.425 15.62ZM17.395 17L16.231 15.86C17.575 14.684 18.271 13.28 18.271 11.42C18.271 9.548 17.575 8.168 16.231 6.98L17.395 5.84C18.979 7.088 19.963 8.984 19.963 11.42C19.963 13.856 18.979 15.752 17.395 17Z"
        fill={props.fillinside}
      />
    </svg>
  )
}
export default InfoIcon
