import { makeAutoObservable, runInAction } from 'mobx'
import type Store from './Store'
import { EAlertTypes } from './AlertsStore'

interface IExchangeQuota {
  quote_id: string
  expire_time: number
  expire_timestamp: number
  from_coin: string
  from_amount: string
  request_amount: string
  request_coin: string
  to_coin: string
  to_amount: string
  exchange_rate: string
  fee_amount: string
  fee_coin: string
  merchant_id: number
  user_id: number
}

class ExchangeStore {
  constructor(private readonly rootStore: Store) {
    // private readonly rootStore: Store
    // this.rootStore = rootStore
    makeAutoObservable(this)
  }

  public isGeneratingQuota = false

  async createQuota(quoteParams: {
    from_coin?: string
    to_coin?: string
    request_coin?: string
    request_amount: string | number
  }): Promise<IExchangeQuota | undefined> {
    runInAction(() => (this.isGeneratingQuota = true))
    const response = await this.rootStore.api.post<IExchangeQuota>(
      '/jsapi/exchange/createquota',
      quoteParams
    )
    runInAction(() => (this.isGeneratingQuota = false))
    return response
  }

  /**
   * Provides user with the available amount of a specific coin that could be spent
   *
   * Backend calculates fees etc
   */
  async getCoinExchangeLimits(
    coin: string
  ): Promise<{ coin: string; limit: string } | undefined> {
    return await this.rootStore.api.post<{ coin: string; limit: string }>(
      '/jsapi/exchange/limit',
      { coin }
    )
  }

  async createExchange(
    quote_id: string
  ): Promise<{ success: boolean } | undefined> {
    const response = await this.rootStore.api.post<{ success: boolean }>(
      '/jsapi/exchange/createexchange',
      {
        quote_id
      }
    )
    if (response !== undefined) {
      this.rootStore.alertsStore.addAlert({
        id: 'exchange-success',
        title: 'Success!',
        content: 'Exchange request is completed.',
        type: EAlertTypes.SUCCESS,
        timeout: 10000
      })
      void this.rootStore.balancesStore.getBalances({
        ...this.rootStore.balancesStore.filters.searchString
      })
    }
    return response
  }
}

export default ExchangeStore
