import { observer } from 'mobx-react'
import { type ChangeEvent } from 'react'
import styles from './search.module.scss'
import type FiltersStore from 'src/models/FiltersStore'
import './Search.scss'

export type TSearchFilter = {
  title: string
  value: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}
// TODO: Move to new filter and change type name
type TTSearchFilter = {
  title: string
  /**
   * Specific filter store
   */
  filtersStore: FiltersStore<any>
  /**
   * Filter name that is used to send to BE, found in config of all FilterStore's
   */
  filterNameFromFilterConfig: string
}

const Search = observer(({ title, value, onChange }: TSearchFilter) => {
  return (
    <>
      <div className="btn-group">
        <div
          style={{
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
            backgroundColor: '#2E2E2E',
            padding: '0 10px',
            fontSize: '0.8em',
            color: '#F3FF00',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex'
          }}
        >
          {title}
        </div>
        <input
          name={'foreignId'}
          placeholder={'Search ...'}
          className={styles.searchInput}
          value={value}
          onChange={(e) => {
            onChange(e)
          }}
        />
      </div>
    </>
  )
})

export const SearchFilter: React.FC<TTSearchFilter> = observer(
  ({ title, filtersStore, filterNameFromFilterConfig }) => {
    return (
      <>
        <div className="btn-group">
          <div
            style={{
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
              backgroundColor: '#2E2E2E',
              padding: '0 10px',
              fontSize: '0.8em',
              color: '#F3FF00',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex'
            }}
            className="SearchFilter"
          >
            {title}
          </div>
          <input
            name={'foreignId'}
            placeholder={'Search ...'}
            className={styles.searchInput}
            value={filtersStore.searchString[filterNameFromFilterConfig] ?? ''}
            onChange={(e) => {
              filtersStore.setSearchFields([
                {
                  field: filterNameFromFilterConfig,
                  value: e.target.value
                }
              ])
            }}
          />
        </div>
      </>
    )
  }
)

export default Search
