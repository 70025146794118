import { observer } from 'mobx-react'
import { Col, Row, Table } from 'react-bootstrap'
import Button, { EButtonType } from '../../ui/Button'
import Payin from '../../assets/images/svg/Payin.svg'
import colors from '../../assets/scss/themes/colors.module.scss'
import Payout from '../../assets/images/svg/payout.svg'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import styles from './balancePage.module.scss'
import { useStore } from '../../models/Store'
import useSideBarModal from 'src/hooks/useSideBarModal'
import ExchangeIcon from 'src/assets/images/svg/ExchangeIcon'
import ExchangeModal from 'src/components/Modals/ExchangeModal/ExchangeModal'

const Balance = observer(() => {
  const { balancesStore, addressesStore } = useStore()
  const navigate = useNavigate()
  const { openModal } = useSideBarModal()
  return (
    <>
      <div className={styles.balance}>
        <Table responsive>
          <thead>
            <tr>
              <th>Balances</th>
              <th colSpan={3}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {balancesStore.items
              .slice()
              .sort((a, b) => parseInt(b.balance) - parseInt(a.balance))
              .map((item) => {
                const imageName =
                  item.coin.toLowerCase().search('usdt') !== -1
                    ? 'usdt'
                    : item.coin.toLowerCase()
                return (
                  <tr key={item.id}>
                    <td>
                      <div className={'balance-info'}>
                        <div className={'balance-info-icon'}>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `/assets/images/ps/${imageName}.svg`
                            }
                            alt={imageName}
                          />
                        </div>
                        <div className={'balance-info-data'}>
                          <p>{item.coin}</p>
                          <p className={'color-main'}>
                            {item.balance} {item.coin}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td width={150}>
                      <Button
                        classes={styles.actionButton}
                        disabled={!item.is_available_exchange}
                        icon={<ExchangeIcon stroke={colors.main} />}
                        title={'Exchange'}
                        onClick={() => {
                          openModal(
                            <ExchangeModal
                              chosenCoin={{
                                ...item,
                                label: item.coin,
                                value: item
                              }}
                            />,
                            <ExchangeIcon stroke={colors.main} />,
                            'Exchange'
                          )
                        }}
                        buttonType={EButtonType.LINK}
                      />
                    </td>
                    <td width={150}>
                      <Button
                        classes={styles.actionButton}
                        disabled={!item.is_available_topup}
                        icon={<Payin fill={colors.main} stroke={colors.main} />}
                        title={'Top up'}
                        onClick={() => {
                          if (item.is_virtual) {
                            navigate(`topup-fiat/${item.coin}`, {
                              state: {
                                currency: item.currency
                              }
                            })
                            return
                          }
                          navigate(`topup/${item.coin}`, {
                            state: {
                              address: item.address,
                              currency: item.currency
                            }
                          })
                        }}
                        buttonType={EButtonType.LINK}
                      />
                    </td>
                    <td width={150}>
                      <Button
                        classes={styles.actionButton}
                        disabled={!item.is_available_withdraw}
                        icon={
                          <Payout fill={colors.main} stroke={colors.main} />
                        }
                        title={'Withdraw'}
                        onClick={() => {
                          if (item.is_virtual) {
                            navigate(`withdraw-fiat/${item.coin}`, {
                              state: {
                                currency: item.currency
                              }
                            })
                            return
                          }
                          addressesStore.setSelectedCoinForWithdraw(item)
                          navigate(
                            `withdraw/${item.coin}/network/${item.network_id}`
                          )
                        }}
                        buttonType={EButtonType.LINK}
                      />
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </Table>
      </div>
      <Outlet />
    </>
  )
})

export default Balance
