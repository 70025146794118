import { observer } from 'mobx-react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'
import Button, { EButtonType } from 'src/ui/Button'
import BaseDataTable from 'src/components/BaseDataTable/BaseDataTable'
import { type TableColumn } from 'react-data-table-component'
import DotsMenuDropdown from 'src/components/DotsMenuDropdown/DotsMenuDropdown'
import { useStore } from 'src/models/Store'
import { DropdownFilterNew } from 'src/ui/Filters/DropdownFilter'
import {
  withdrawAddressesFilterConfig,
  type TWithdrawAddress
} from 'src/models/WithdrawAddressesStore'
import '../AddressesPage.scss'
import { SearchFilter } from 'src/ui/Filters/Search'
import ClearFiltersButton from 'src/ui/Filters/ClearFiltersButton/ClearFiltersButton'
import Preloader from '../../../ui/Preloader'
import HamburgerIcon from 'src/assets/images/svg/HamburgerIcon'
import useSideBarModal from 'src/hooks/useSideBarModal'

const columns: Array<TableColumn<TWithdrawAddress>> = [
  {
    name: 'Action',
    cell: (row) => actionMenuButton(row),
    button: true,
    minWidth: '50px',
    id: 1
  },
  {
    name: 'ID',
    selector: (row) => row.id,
    allowOverflow: false,
    center: true,
    maxWidth: '200px',
    grow: 0,
    style: {
      backgroundColor: '#2E2E2E',
      borderRadius: 10,
      height: 25,
      alignSelf: 'center'
    },
    id: 2
  },
  {
    name: 'Network',
    cell: (row) => displayNetworkWithImage(row.network_name),
    id: 3
  },
  { name: 'Address', selector: (row) => row.slug, id: 5 },
  { name: 'Wallet name', selector: (row) => row.name, id: 4 }
]

const displayNetworkWithImage = (networkName: string): JSX.Element => {
  return (
    <div>
      <img
        src={`/assets/images/${networkName.toLowerCase()}.svg`}
        style={{ marginRight: 10 }}
      />
      {networkName}
    </div>
  )
}

const actionMenuButton = (address: TWithdrawAddress): JSX.Element => {
  const navigate = useNavigate()

  return (
    <DotsMenuDropdown>
      <Dropdown.Item
        onClick={() => {
          navigate(`manage/edit/address/${address.id}`)
        }}
      >
        Edit Address
      </Dropdown.Item>
      <Dropdown.Item
        className="ApiKeysPage-DropDownDeleteButton"
        onClick={() => {
          navigate(`manage/delete/address/${address.id}`)
        }}
      >
        Delete Address
      </Dropdown.Item>
    </DotsMenuDropdown>
  )
}

const CustomAddressesFilters = observer((): JSX.Element => {
  const { withdrawAddressesStore, initialStore } = useStore()
  const filterNetworks = initialStore.networks.items.map((coin) => {
    return { name: coin.name, value: coin.id.toString() }
  })

  return (
    <>
      <DropdownFilterNew
        filterName="Network"
        noActiveFilterName="All networks"
        filterItems={filterNetworks}
        filtersStore={withdrawAddressesStore.filters}
        filterNameFromFilterConfig={withdrawAddressesFilterConfig['2']}
      />
      <SearchFilter
        title="Wallet name"
        filtersStore={withdrawAddressesStore.filters}
        filterNameFromFilterConfig={withdrawAddressesFilterConfig['4']}
      />
      <SearchFilter
        title="Address"
        filtersStore={withdrawAddressesStore.filters}
        filterNameFromFilterConfig={withdrawAddressesFilterConfig['5']}
      />
      <ClearFiltersButton filtersStore={withdrawAddressesStore.filters} />
    </>
  )
})

const CustomAddressesPage = observer(() => {
  const { withdrawAddressesStore } = useStore()
  const navigate = useNavigate()
  const { closeModal, openModal } = useSideBarModal()

  if (withdrawAddressesStore.isLoading) return <Preloader />

  return (
    <>
      <div className="AddressesPage">
        <div className="AddressesPage-Actions">
          <div className="AddressesPage-Filters">
            <CustomAddressesFilters />
          </div>
          <Button
            icon={<HamburgerIcon />}
            title={`Filters (${withdrawAddressesStore.filters.activeFilterCount})`}
            buttonType={EButtonType.FILTER}
            classes="subfilters-button"
            onClick={() => {
              openModal(
                <div className="subfilters-modal">
                  <CustomAddressesFilters />
                  <Button
                    title="Apply"
                    // Simply close modal since filters apply on filter change
                    onClick={() => {
                      closeModal()
                    }}
                    wide
                  />
                </div>,
                null,
                'Filters'
              )
            }}
          />
          <Button
            title="Add wallet"
            onClick={() => {
              navigate('manage/add')
            }}
          />
        </div>
        <BaseDataTable
          columns={columns}
          data={withdrawAddressesStore.withdrawAddressesPagination.items}
          paginationLogic={withdrawAddressesStore.pagination}
        />
      </div>
      <Outlet />
    </>
  )
})

export default CustomAddressesPage
