import Button from '../../ui/Button'
import colors from '../../assets/scss/themes/colors.module.scss'
import Logo from '../../assets/images/CitronLogoWhite.svg'
import { observer } from 'mobx-react'
import { useStore } from '../../models/Store'
import Input from 'src/ui/Input/Input'
import './BusinessSignUp.scss'
import CitronBusinessSignUpVideo from '../../assets/video/citron-business-sign-up-video.mp4'
import { type SubmitHandler, useForm } from 'react-hook-form'

type BusinessSignUpForm = {
  company_industry: string
  company_name: string
  company_website: string
  country_of_incorporation: string
  email: string
  name: string
  surname: string
  message: string
}

const BusinessSignUp = observer(() => {
  const { userStore } = useStore()

  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitting, isSubmitSuccessful }
  } = useForm<BusinessSignUpForm>()

  const onSubmit: SubmitHandler<BusinessSignUpForm> = async (data) => {
    await userStore.businessRegister({
      ...data,
      fullname: data.name + data.surname
    })
  }

  return (
    <>
      <div className="BusinessSignUp">
        <div
          className={`BusinessSignUp-Container ${
            isSubmitSuccessful ? '__Success' : ''
          }`}
        >
          <div className="BusinessSignUp-Form">
            <h3>Become a client</h3>
            <p>
              Leave a request and a personal manager will contact you in 5
              minutes via e-mail
            </p>
            <Input label="Name" {...register('name', { required: true })} />
            <Input
              label="Surname"
              {...register('surname', { required: true })}
            />
            <Input
              label="Company name"
              {...register('company_name', { required: true })}
            />
            <Input
              type="email"
              label="E-mail"
              {...register('email', { required: true })}
            />
            <Input label="Company website" {...register('company_website')} />
            <Input
              label="Country of incorporation"
              {...register('country_of_incorporation')}
            />
            <Input label="Company Industry" {...register('company_industry')} />
            <Input
              label="Describe your needs in few lines"
              {...register('message')}
            />
            <Button
              title="Send the request"
              disabled={!isValid || isSubmitting}
              wide
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
        <div
          className={`BusinessSignUp-Info ${
            isSubmitSuccessful ? '__Success' : ''
          }`}
        >
          <Logo fill={colors.main} />
          <h1>Become a client</h1>
          <p>
            Leave a request and a personal manager will contact you in 5 minutes
            via e-mail
          </p>
          <video autoPlay width={400} height={200} loop>
            <source type="video/mp4" src={CitronBusinessSignUpVideo} />
          </video>
        </div>
      </div>
      {isSubmitSuccessful && (
        <div
          className={`BusinessSignUp-SubmitSuccess ${
            isSubmitSuccessful ? '__Success' : ''
          }`}
        >
          <h1>Thank you!</h1>
          <p>Our personal manager will contact you in 5 minutes via e-mail</p>
          <a className="ctr-btn primary bussinesSignUp" href="http://citrn.io/">
            Back to landing page
          </a>
        </div>
      )}
    </>
  )
})

export default BusinessSignUp
