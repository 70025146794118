/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { Col, Row } from 'react-bootstrap'
import { observer } from 'mobx-react'
import { useStore } from '../../models/Store'
import { NavLink, Outlet, useParams } from 'react-router-dom'
import { useEffect } from 'react'

const Filters = observer(() => {
  const store = useStore()
  const params = useParams()

  // Change filter action
  useEffect(() => {
    if (params.page) {
      const value = params.page === 'all' ? '' : params.page !== 'crypto'
      store.balancesStore.filters.setSearchFields([
        { field: 'is_virtual', value }
      ])
    }
  }, [params.page])

  return (
    <div className={'nav-pills'}>
      <NavLink
        to={'all'}
        className={({ isActive }) => (isActive ? 'pill active' : 'pill')}
      >
        Show all
      </NavLink>
      <NavLink
        to={'crypto'}
        className={({ isActive }) => (isActive ? 'pill active' : 'pill')}
      >
        Crypto
      </NavLink>
      <NavLink
        to={'fiat'}
        className={({ isActive }) => (isActive ? 'pill active' : 'pill')}
      >
        Fiat
      </NavLink>
    </div>
  )
})

const BalancesPage = observer(() => {
  return (
    <>
      <Row
        style={{
          alignItems: 'center',
          marginBlock: 30
        }}
      >
        <Col xs={'auto'}>
          <Filters />
        </Col>
      </Row>
      <Outlet />
    </>
  )
})

export default BalancesPage
