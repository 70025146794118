import type React from 'react'
import '../WithdrawPage.scss'
import BaseSideBarComponent from 'src/components/BaseSideBarComponent/BaseSideBarComponent'
import WithdrawIcon from 'src/assets/images/svg/WithdrawIcon'
import { colors } from 'src/assets/scss/themes'
import Button, { EButtonType } from 'src/ui/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import ModalOTPInput from 'src/components/ModalOTPInput/ModalOTPInput'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import { useStore } from 'src/models/Store'
import { type UseParamsWithdrawManage } from '../WithdrawPage'
import NoTFASideBarComponent from 'src/components/EnableTFASidebarComponent/EnableTFASidebarComponent'

type WithdrawFormInputs = {
  tfa_code: string
}

const WitdrawAddressesDelete: React.FC = observer(() => {
  const [formStep, setFormStep] = useState<'confirmDeletion' | '2fa'>(
    'confirmDeletion'
  )
  const navigate = useNavigate()
  const { withdrawAddressesStore, userStore } = useStore()
  const { addressId, networkId } =
    useParams<UseParamsWithdrawManage>() as UseParamsWithdrawManage
  const addressToDelete = withdrawAddressesStore.withdrawAddresses.find(
    (address) => address.id === parseInt(addressId)
  )

  const {
    control,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm<WithdrawFormInputs>()

  // Get addresses on first render in case of a direct link
  useEffect(() => {
    if (withdrawAddressesStore.withdrawAddresses.length <= 0) {
      void withdrawAddressesStore.getWithdrawAddresses({
        network_id: parseInt(networkId)
      })
    }
  }, [])

  const onSubmit: SubmitHandler<WithdrawFormInputs> = async ({ tfa_code }) => {
    if (addressToDelete !== undefined) {
      const response = await withdrawAddressesStore.deleteAddress({
        id: addressToDelete?.id,
        tfa_code
      })

      if (response !== undefined) {
        navigate('../..')
      }
    }
  }

  const renderFormStep = (): JSX.Element => {
    if (formStep === 'confirmDeletion') {
      return (
        <div className="WithdrawPage">
          <h3>Are you sure you want to delete this wallet?</h3>
          <div className="WithdrawPage-AddressManage">
            <div>
              {addressToDelete?.name}
              <div className="WithdrawPage-AddressManage__slug">
                {addressToDelete?.slug}
              </div>
            </div>
          </div>
          <div className="WithdrawPage-ButtonGroup">
            <Button
              title="Delete wallet"
              onClick={() => {
                setFormStep('2fa')
              }}
            />
            <Button
              title="Cancel"
              buttonType={EButtonType.SECONDARY}
              onClick={() => {
                navigate(-1)
              }}
            />
          </div>
        </div>
      )
    }
    if (formStep === '2fa') {
      return (
        <ModalOTPInput
          control={control}
          isSubmitting={isSubmitting}
          onSubmit={handleSubmit(onSubmit)}
        />
      )
    }
    return <div>Something went wrong, please contact support</div>
  }
  return (
    <BaseSideBarComponent
      headerIcon={<WithdrawIcon stroke={colors.main} />}
      headerTitle={'Withdraw'}
    >
      {userStore.user.tfa_enabled ? (
        renderFormStep()
      ) : (
        <NoTFASideBarComponent />
      )}
    </BaseSideBarComponent>
  )
})
export default WitdrawAddressesDelete
