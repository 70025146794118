import { observer } from 'mobx-react'
import type React from 'react'
import { useStore } from '../../models/Store'
import { NavLink, useLocation } from 'react-router-dom'
import './TransactionsPage.scss'

const TransactionFilters: React.FC = observer(() => {
  const { transactionStore, initialStore } = useStore()
  const location = useLocation()

  const setTransactionType = (type: string): void => {
    transactionStore.filters.setSearchFields([
      { field: 'transaction_type', value: type }
    ])
  }

  return (
    <>
      <div className={'nav-pills'}>
        <NavLink
          key={'all'}
          to={`/transactions/all`}
          className={`pill ${location.search.includes('all') ? 'active' : ''}`}
          onClick={() => {
            setTransactionType('')
          }}
        >
          Show all
        </NavLink>
        {Object.keys(initialStore.transactions_types).map((key) => {
          return (
            <NavLink
              key={key}
              to={`/transactions/${key}`}
              className={`pill ${
                location.search.includes(key) ? 'active' : ''
              }`}
              onClick={() => {
                setTransactionType(key)
              }}
            >
              {initialStore.transactions_types[key]}
            </NavLink>
          )
        })}
      </div>
    </>
  )
})

export default TransactionFilters
