import type React from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useStore } from 'src/models/Store'
import Button from 'src/ui/Button'
import Input from 'src/ui/Input/Input'
import './NewPasswordAfterReset.scss'
import { useEffect } from 'react'
import { EAlertTypes } from 'src/models/AlertsStore'
import { colors } from 'src/assets/scss/themes'

type NewPasswordAfterResetFormInputs = {
  new_password: string
  re_new_password: string
}

const NewPasswordAfterReset: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    trigger
  } = useForm<NewPasswordAfterResetFormInputs>({
    defaultValues: { new_password: '', re_new_password: '' },
    criteriaMode: 'all',
    mode: 'all'
  })
  const { userStore, alertsStore } = useStore()
  const navigate = useNavigate()
  const searchParams = useSearchParams()

  useEffect(() => {
    const resetCode = searchParams[0].get('code')
    if (resetCode === null) {
      alertsStore.addAlert({
        id: 'password-reset-failure',
        title: 'Reset password',
        content: 'Reset code not found',
        type: EAlertTypes.ERROR,
        timeout: 10000
      })
      navigate('/login')
    } else {
      // Fix later
      const getCode = async (): Promise<void> => {
        const resetCodeValidation = await userStore.resetPasswordConfirmation(
          resetCode
        )
        if (!resetCodeValidation) {
          navigate('/login')
        }
      }
      getCode().catch(() => {})
    }
  }, [])

  const onSubmit: SubmitHandler<NewPasswordAfterResetFormInputs> = async ({
    new_password,
    re_new_password
  }) => {
    const response = await userStore.createNewPassword(
      new_password,
      re_new_password,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      searchParams[0].get('code')!
    )
    if (response) {
      navigate('/login')
    }
  }
  useEffect(() => {
    // Trigger all validation on page load
    void trigger()
  }, [])

  const currentPassword = watch('new_password')
  return (
    <div className="NewPasswordAfterReset">
      <h3>Change password</h3>
      <Input
        label="New password"
        type="password"
        {...register('new_password', {
          required: { message: 'Please enter a new password', value: true },
          minLength: {
            message: 'Passwords must be at least 8 characters long',
            value: 8
          },
          validate: {
            latinOnly: (value) =>
              /^[A-Za-z0-9!@#$%^&*()'"_+{}[\]:;<>,.?~\\/-]+$/.test(value) ||
              'Only latin characters allowed',
            specialCharacters: (value) =>
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*?&#:;,.^()-]).+$/.test(
                value
              ) ||
              'At least one lowercase letter, uppercase letter, digit and special character required'
          }
        })}
      />
      <Input
        label="Confirm new password"
        type="password"
        {...register('re_new_password', {
          validate: (value) =>
            value === currentPassword || 'Passwords must match'
        })}
      />
      <ul
        style={{
          listStyle: 'none',
          padding: 0
        }}
      >
        <li
          className={`NewPasswordAfterReset-PasswordRequirements ${
            !isValid && (errors.new_password?.types?.latinOnly as boolean)
              ? 'Incomplete'
              : 'Complete'
          }`}
        >
          <p>Letters of the Latin alphabet only</p>
        </li>
        <li
          className={`NewPasswordAfterReset-PasswordRequirements ${
            !isValid &&
            ((errors.new_password?.types?.minLength as boolean) ||
              (errors.new_password?.types?.required as boolean))
              ? 'Incomplete'
              : 'Complete'
          }`}
        >
          <p>Passwords must be at least 8 characters long</p>
        </li>
        <li
          className={`NewPasswordAfterReset-PasswordRequirements ${
            !isValid &&
            (errors.new_password?.types?.specialCharacters as boolean)
              ? 'Incomplete'
              : 'Complete'
          }`}
        >
          <p>One capital letter, number or special character</p>
        </li>

        {errors.re_new_password?.message !== undefined && (
          <p style={{ color: colors.error }}>
            {errors.re_new_password.message}
          </p>
        )}
      </ul>
      <Button title="Confirm changes" onClick={handleSubmit(onSubmit)} />
    </div>
  )
}
export default NewPasswordAfterReset
