import { type FieldError } from 'react-hook-form'
import './Input.scss'
import { forwardRef, type ComponentPropsWithRef } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import AlertIcon from 'src/assets/images/svg/AlertIcon'
import { colors } from 'src/assets/scss/themes'

interface InputProps extends ComponentPropsWithRef<'input'> {
  label: string
  /**
   * sets width to 100%
   */
  wide?: true
  /**
   * Errors object from react-hook-form ONLY
   *
   * Will display all error messages near the placeholder as per design
   */
  errors?: FieldError | undefined
}
/**
 * Input styled per project requirements, used with react-hook-form
 */
const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ label, wide, errors, ...props }, ref) => {
    return (
      <div>
        <div className="Input-Context">
          <div>{label}</div>
          {errors?.message !== undefined && (
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>{errors.message}</Tooltip>}
            >
              <div>
                <AlertIcon fill={colors.error} />
              </div>
            </OverlayTrigger>
          )}
        </div>
        <input
          className={`Input ${wide ? '__wide' : ''} ${
            errors?.message !== undefined ? '__error' : ''
          }`}
          ref={ref}
          {...props}
        />
      </div>
    )
  }
)

Input.displayName = 'Input'
export default Input
