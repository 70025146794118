import type React from 'react'
import './CreateApiKey.scss'
import '../ApiKeysPageModals.scss'
import { observer } from 'mobx-react'
import BaseSideBarComponent from 'src/components/BaseSideBarComponent/BaseSideBarComponent'
import LinkIcon from 'src/assets/images/svg/LinkIcon'
import Input from 'src/ui/Input/Input'
import { type SubmitHandler, useForm } from 'react-hook-form'
import Button, { EButtonType } from 'src/ui/Button'
import { useState } from 'react'
import OTPCodeInput from '../OTPCodeInput/OTPCodeInput'
import { useStore } from 'src/models/Store'
import InfoIcon from 'src/assets/images/svg/InfoIcon'
import CopyTextComponent from 'src/ui/CopyTextComponent/CopyTextComponent'
import { colors } from 'src/assets/scss/themes'

type FormInputs = {
  name: string
  tfa_code: string
}

const CreateApiKey: React.FC = observer(() => {
  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm<FormInputs>({ defaultValues: { name: '', tfa_code: '' } })

  const [formStep, setFormStep] = useState<
    'keyCreation' | '2FA' | 'keyCreated'
  >('keyCreation')
  const [createdKey, setCreatedKey] = useState({ key: '', secret: '' })
  const { apiKeysStore, utilityStore } = useStore()

  const isNameInputDisabled = watch('name') === ''

  const onSubmit: SubmitHandler<FormInputs> = async ({ name, tfa_code }) => {
    const response = await apiKeysStore.createApiKey(name, true, [], tfa_code)
    if (response !== undefined) {
      setCreatedKey(response)
      setFormStep('keyCreated')
    }
  }
  const renderFormStep = (): JSX.Element => {
    if (formStep === 'keyCreation') {
      return (
        <>
          <h2>API key activation</h2>
          <Input
            label="Name for your new key"
            {...register('name', { required: true })}
          />
          <div className="CreateApiKey-ButtonGroup">
            <Button
              title="Generate"
              onClick={() => {
                setFormStep('2FA')
              }}
              disabled={isNameInputDisabled}
            />
            <Button
              title="Cancel"
              buttonType={EButtonType.SECONDARY}
              onClick={() => {
                utilityStore.closeSideBarModal()
              }}
            />
          </div>
        </>
      )
    }
    if (formStep === '2FA') {
      return (
        <OTPCodeInput
          control={control}
          label="Please enter the sign-in 2FA code from your authenticator app"
          onSubmit={handleSubmit(onSubmit)}
          isSubmitting={isSubmitting}
          onCancel={() => {
            utilityStore.closeSideBarModal()
          }}
        />
      )
    }
    if (formStep === 'keyCreated') {
      return (
        <>
          <h2>API key activation</h2>
          <ul className="CreateApiKey-InfoList">
            <li>{'Please copy and save your secret and key'}</li>
            <li>{"Don't show it to anybody"}</li>
          </ul>
          <div className="CreateApiKey-InfoSecret">
            <InfoIcon
              fillinside="var(--color-white)"
              fillbackground="var(--color-error)"
            />
            {'You won’t be able to see secret and key again!'}
          </div>
          <div>
            <p>Key</p>
            <CopyTextComponent text={createdKey.key} />
          </div>
          <div>
            <p>Secret</p>
            <CopyTextComponent text={createdKey.secret} />
          </div>
          <Button
            title="Close"
            onClick={() => {
              utilityStore.closeSideBarModal()
            }}
          />
        </>
      )
    }
    return <div>Something went wrong, please contact support</div>
  }

  return (
    <BaseSideBarComponent
      headerTitle="API key activation"
      headerIcon={<LinkIcon stroke={colors.main} />}
      checkTFAEnabled
    >
      <div className="CreateApiKey">{renderFormStep()}</div>
    </BaseSideBarComponent>
  )
})
export default CreateApiKey
