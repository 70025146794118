import { observer } from 'mobx-react'
import { Col, Row, Stack } from 'react-bootstrap'
import { DropdownFilterNew } from '../../ui/Filters/DropdownFilter'
import { SearchFilter } from '../../ui/Filters/Search'
import { useEffect } from 'react'
import { useStore } from '../../models/Store'
import ClearFiltersButton from 'src/ui/Filters/ClearFiltersButton/ClearFiltersButton'
import { useLocation, useNavigate } from 'react-router-dom'
import './TransactionsPage.scss'
import Button, { EButtonType } from 'src/ui/Button'
import HamburgerIcon from 'src/assets/images/svg/HamburgerIcon'
import useSideBarModal from 'src/hooks/useSideBarModal'
import { transactionConfigPageFilters } from 'src/models/TransactionStore'

const TransactionsSubFilter = observer(() => {
  const navigate = useNavigate()
  const location = useLocation()
  const { transactionStore, initialStore } = useStore()
  const { coins, networks } = initialStore
  const { openModal, closeModal } = useSideBarModal()

  useEffect(() => {
    if (
      location.pathname !== '/transactions/all' &&
      transactionStore.filters.filters.transaction_type === ''
    ) {
      navigate('/transactions/all')
    }
  }, [])

  const TransactionSubFilters = (): JSX.Element => {
    return (
      <>
        <DropdownFilterNew
          noActiveFilterName="All"
          filtersStore={transactionStore.filters}
          filterName="Status"
          filterNameFromFilterConfig={transactionConfigPageFilters['6']}
          filterItems={[
            { name: 'Success', value: 'success' },
            { name: 'Failed', value: 'failed' },
            { name: 'Pending', value: 'pending' }
          ]}
        />
        <DropdownFilterNew
          noActiveFilterName="All networks"
          filtersStore={transactionStore.filters}
          filterName="Networks"
          filterNameFromFilterConfig={transactionConfigPageFilters['4']}
          filterItems={networks.items.map((networkItem) => ({
            name: networkItem.name,
            value: networkItem.id.toString()
          }))}
        />
        <DropdownFilterNew
          noActiveFilterName="All Coins"
          filtersStore={transactionStore.filters}
          filterName="Coins"
          filterNameFromFilterConfig={transactionConfigPageFilters['5']}
          filterItems={coins.items.map((coinItem) => ({
            name: coinItem.name,
            value: coinItem.id.toString()
          }))}
        />
        <SearchFilter
          filterNameFromFilterConfig={transactionConfigPageFilters['12']}
          filtersStore={transactionStore.filters}
          title="Foreign ID"
        />
        <SearchFilter
          filterNameFromFilterConfig={transactionConfigPageFilters['3']}
          filtersStore={transactionStore.filters}
          title="Transaction ID"
        />
        <ClearFiltersButton
          filtersStore={transactionStore.filters}
          onClick={() => {
            navigate('/transactions/all')
          }}
        />
      </>
    )
  }

  return (
    <div
      className={'justify-content-between'}
      style={{
        alignItems: 'center',
        marginBottom: 20,
        display: 'flex'
      }}
    >
      <div
        style={{
          height: 25,
          alignItems: 'center'
        }}
        className="subfilters"
      >
        <Stack direction={'horizontal'} gap={2}>
          <TransactionSubFilters />
        </Stack>
      </div>
      {/* // Mobile design filter button that opens a sidebar modal */}
      <Button
        icon={<HamburgerIcon />}
        title={`Filters (${transactionStore.filters.activeFilterCount})`}
        buttonType={EButtonType.FILTER}
        classes="subfilters-button"
        onClick={() => {
          openModal(
            <div className="subfilters-modal">
              <TransactionSubFilters />
              <Button
                title="Apply"
                style={{ marginTop: '20px' }}
                // Simply close modal since filters apply on filter change
                onClick={() => {
                  closeModal()
                }}
                wide
              />
            </div>,
            null,
            'Filters'
          )
        }}
      />
    </div>
  )
})

export default TransactionsSubFilter
