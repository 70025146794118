import { observer } from 'mobx-react'
import { Col } from 'react-bootstrap'
import Button from '../../ui/Button'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { useStore } from '../../models/Store'
import StaySecureImage from '../../assets/images/StaySecureImage.svg'
import StaySafeTFAImage from '../../assets/images/StaySafeTFAImage.svg'
import IntegrateApiKeysImage from '../../assets/images/IntegrateApiKeysImage.svg'
import './ProfilePage.scss'
import AlertIcon from 'src/assets/images/svg/AlertIcon'
import { colors } from 'src/assets/scss/themes'
import LockIcon from 'src/assets/images/svg/LockIcon'
import LinkIcon from 'src/assets/images/svg/LinkIcon'
import ApiKeysTable from '../ApiKeysTable/ApiKeysTable'
import { useEffect, useState } from 'react'

const Security = observer(() => {
  const navigate = useNavigate()
  const location = useLocation()
  const {
    userStore,
    apiKeysStore,
    merchantStore: { currentMerchant }
  } = useStore()
  const { isTFAEnabled } = userStore
  const [isApiKeysTableShown, setIsApiKeysTableShown] = useState<boolean>(false)

  const apiKeyStatus = (): {
    disabledApiKeyCount: number
    enabledApiKeyCount: number
  } => {
    let enabledApiKeyCount = 0
    let disabledApiKeyCount = 0
    apiKeysStore.apiKeys.forEach((apiKey) => {
      if (apiKey.is_enabled) {
        enabledApiKeyCount += 1
      } else {
        disabledApiKeyCount += 1
      }
    })
    return { disabledApiKeyCount, enabledApiKeyCount }
  }

  useEffect(() => {
    void apiKeysStore.getApiKeys({ page: 1, page_size: 50 })
  }, [currentMerchant])

  return (
    <>
      <Col>
        <div className="ProfilePage">
          <div className="ProfilePage-Section">
            <div>
              <h3>Security</h3>
              <p>A complex password enhances the security of your account.</p>
              <Button
                title="Change password"
                onClick={() => {
                  navigate('password-change')
                }}
              />
            </div>
            <div className="ProfilePage-Image">
              <StaySecureImage />
            </div>
          </div>
        </div>

        <div className="ProfilePage">
          <div className="ProfilePage-Section">
            <div>
              <div className="ProfilePage-Section__tfaStatus">
                <h3>Two-Factor Authentication</h3>
                <span
                  className={`status status-${
                    isTFAEnabled ? 'success' : 'failed'
                  }`}
                />
                <span>{isTFAEnabled ? 'Enabled' : 'Disabled'}</span>
              </div>
              <p>
                <AlertIcon fill={colors.main} color={colors.bg} />
                {isTFAEnabled
                  ? 'Keep 2FA turned on for additional security'
                  : 'You can connect Google Authenticator for additional security.'}
              </p>
              <Button
                title={isTFAEnabled ? 'Disable' : 'Enable'}
                onClick={() => {
                  navigate('2fa/setup')
                }}
                icon={<LockIcon fill={colors.main} stroke={colors.bg} />}
              />
            </div>
            <div className="ProfilePage-Image">
              <StaySafeTFAImage />
            </div>
          </div>
        </div>

        <div className="ProfilePage">
          <div className="ProfilePage-Section">
            <div>
              <div className="ProfilePage-Section__apiKeys">
                <h3>API keys</h3>
                <div>
                  <span className={`status status-success`} />
                  {apiKeyStatus().enabledApiKeyCount} Active
                </div>
                <div>
                  <span className={`status status-failed`} />
                  {apiKeyStatus().disabledApiKeyCount} Disabled
                </div>
              </div>
              <p>Remember, you are able to see the key only once!</p>
              <Button
                title={!isApiKeysTableShown ? 'Show all' : 'Hide table'}
                onClick={() => {
                  setIsApiKeysTableShown(!isApiKeysTableShown)
                }}
              />
              <Button
                title="Create New"
                onClick={() => {
                  navigate('apikeys/create')
                }}
                icon={<LinkIcon fill={colors.main} stroke={colors.bg} />}
              />
            </div>
            <div className="ProfilePage-Image">
              <IntegrateApiKeysImage />
            </div>
          </div>
          {isApiKeysTableShown ? <ApiKeysTable /> : null}
        </div>
      </Col>
      <TransitionGroup>
        <CSSTransition
          key={location.key}
          classNames="slide-left"
          timeout={1000}
          appear
          mountOnEnter
        >
          <Outlet />
        </CSSTransition>
      </TransitionGroup>
    </>
  )
})

export default Security
