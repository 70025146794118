import BaseDataTable from 'src/components/BaseDataTable/BaseDataTable'
import './TransactionReports.scss'
import { type TableColumn } from 'react-data-table-component'
import Button, { EButtonSize } from 'src/ui/Button'
import Payin from 'src/assets/images/svg/Payin.svg'
import useSideBarModal from 'src/hooks/useSideBarModal'
import CreateTransactionReport from './CreateTransactionReport'
import { useEffect } from 'react'
import ArrowIcon from 'src/assets/images/svg/ArrowIcon'
import { colors } from 'src/assets/scss/themes'
import { useStore } from 'src/models/Store'
import { observer } from 'mobx-react'
import { type IReport } from 'src/models/ReportsStore'

const columns: Array<TableColumn<IReport>> = [
  { name: 'Created', selector: (row) => row.date_created, id: 1 },
  {
    name: 'Action',
    cell: (row) => downloadReportButton(row.id),
    button: true,
    minWidth: '140px',
    id: 3
  }
]

const downloadReportButton = (reportId: number): JSX.Element => {
  const { reportsStore } = useStore()

  return (
    <Button
      onClick={() => {
        reportsStore.getReportDownloadable(reportId)
      }}
      icon={<Payin stroke={colors.bg} />}
      title="Download"
      size={EButtonSize.SM}
    />
  )
}

const TransactionReports: React.FC = observer(() => {
  const { openModal } = useSideBarModal()
  const { reportsStore } = useStore()
  const { reports } = reportsStore

  useEffect(() => {
    reportsStore.filters.setSearchFields([
      { field: 'type', value: 'transactions' }
    ])
  }, [])

  return (
    <div className="TransactionReports">
      <div className="TransactionReports-Actions">
        <Button
          title="Create report"
          onClick={() => {
            openModal(
              <CreateTransactionReport />,
              <ArrowIcon stroke={colors.main} />,
              'Create transaction report'
            )
          }}
        />
      </div>
      <BaseDataTable
        columns={columns}
        data={reports}
        paginationLogic={reportsStore.pagination}
      />
    </div>
  )
})
export default TransactionReports
