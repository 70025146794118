/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-floating-promises */
// This will be changed later for an updated design
import React, { useRef, useState } from 'react'
import { observer } from 'mobx-react'
import QRCode from 'react-qr-code'
import { Col, Row } from 'react-bootstrap'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Button, { EButtonSize, EButtonType } from '../../ui/Button'
import colors from '../../assets/scss/themes/colors.module.scss'
import Payin from '../../assets/images/svg/Payin.svg'
import Info from '../../assets/images/svg/informationlineforheading.svg'
import BaseSideBarComponent from '../../components/BaseSideBarComponent/BaseSideBarComponent'
import styles from './payin.module.scss'
import CopyTextComponent from 'src/ui/CopyTextComponent/CopyTextComponent'
import { useStore } from 'src/models/Store'

const PayinPage = observer(() => {
  const location = useLocation()
  const navigate = useNavigate()
  const { id } = useParams()
  const { balancesStore } = useStore()
  if (!location.state.address) return <p>No address provided</p>
  console.log(id)
  console.log(balancesStore.findBalanceItem('coin', id as string))
  const currentCoin = balancesStore.findBalanceItem('coin', id as string)
  return (
    <BaseSideBarComponent
      headerIcon={<Payin fill={colors.main} stroke={colors.main} />}
      headerTitle={`${location.state.currency as string} Send (Top up)`}
    >
      <div className={styles.payinPage}>
        <div
          className={styles.info}
          style={{ fontSize: '.8em', alignItems: 'center' }}
        >
          <Info fill={colors.error} color={colors.white} />
          <p>
            Please check carefully your network selection. If the top up is
            submitted within the wrong network, the funds will be lost.
          </p>
        </div>
        <div className={styles.qrWrapper}>
          <QRCode
            value={location.state.address}
            style={{ width: 180, height: 180 }}
          />
        </div>
        <Row>
          <Col>
            <p className={'text-center'} style={{ fontSize: '.7em' }}>
              You can copy deposit address below
            </p>
          </Col>
        </Row>
        <CopyTextComponent text={location.state.address} />
        {currentCoin?.memo && (
          <>
            <p className={styles.memo}>Memo</p>
            <CopyTextComponent text={currentCoin.memo} />
          </>
        )}
        <Button
          classes={styles.doneBtn}
          buttonType={EButtonType.PRIMARY}
          onClick={() => {
            navigate('../..')
          }}
          title={'Done'}
          style={{ width: '100%' }}
        />
      </div>
    </BaseSideBarComponent>
  )
})

export default PayinPage
