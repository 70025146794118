import type React from 'react'
import { Dropdown } from 'react-bootstrap'
import { type TableColumn } from 'react-data-table-component'
import BaseDataTable from 'src/components/BaseDataTable/BaseDataTable'
import DotsMenuDropdown from 'src/components/DotsMenuDropdown/DotsMenuDropdown'
import { useStore } from 'src/models/Store'
import './ApiKeysTable.scss'
import { useEffect } from 'react'
import { type IApiKey } from 'src/models/ApiKeysStore'
import { Outlet, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'

const columns: Array<TableColumn<IApiKey>> = [
  {
    name: 'Action',
    cell: (row) => actionMenuButton(row),
    button: true
  },
  {
    name: 'Status',
    cell: (row) => (
      <div
        className={`TeamPage-StatusColumn ${
          row.is_enabled ? 'Active' : 'Deactivated'
        }`}
      >
        <span>{row.is_enabled ? 'Activated' : 'Disabled'}</span>
      </div>
    )
  },
  {
    name: 'ID',
    selector: (row) => row.id,
    allowOverflow: false,
    center: true,
    style: {
      backgroundColor: '#2E2E2E',
      borderRadius: 10,
      height: 25,
      alignSelf: 'center'
    }
  },
  { name: 'Key name', selector: (row) => row.name },
  { name: 'Activated at', selector: (row) => row.activated },
  {
    name: 'IP addresses whitelist',
    grow: 3,
    selector: (row) => row.ips_white_list.toString()
  }
]

const actionMenuButton = (apiKeys: IApiKey): JSX.Element => {
  const { id, is_enabled } = apiKeys
  const { utilityStore } = useStore()
  const navigate = useNavigate()
  return (
    <DotsMenuDropdown>
      <Dropdown.Item
        onClick={() => {
          if (!utilityStore.isTFAEnabledWithError()) {
            return
          }
          // Can't pass api_whitelist array, so will have to fetch in the modal itself
          navigate(`apikeys/manage/${apiKeys.id}`, { state: { id } })
        }}
      >
        Manage
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() => {
          if (!utilityStore.isTFAEnabledWithError()) {
            return
          }
          navigate(`apikeys/disable/${apiKeys.id}`, {
            state: { id, is_enabled }
          })
        }}
      >
        {is_enabled ? 'Disable' : 'Enable'}
      </Dropdown.Item>
      <Dropdown.Item
        className="ApiKeysTable-DropDownDeleteButton"
        onClick={() => {
          if (!utilityStore.isTFAEnabledWithError()) {
            return
          }
          navigate(`apikeys/delete/${apiKeys.id}`, { state: { id } })
        }}
      >
        Delete
      </Dropdown.Item>
    </DotsMenuDropdown>
  )
}
/**
 * TODO: Not a page now, rename
 */
const ApiKeysTable: React.FC = observer(() => {
  const { apiKeysStore, merchantStore, utilityStore } = useStore()
  const navigate = useNavigate()

  useEffect(() => {
    apiKeysStore.subscribe()
    return () => {
      apiKeysStore.unsubscribe()
    }
  }, [])

  return (
    <div className="ApiKeysTable">
      {/* <div className="ApiKeysPage-Actions">
        <Button
          title="Create API keys"
          classes="ApiKeyButton"
          icon={<LinkIcon />}
          onClick={() => {
            if (!utilityStore.isTFAEnabledWithError()) {
              return
            }
            navigate('key/create')
          }}
        />
      </div> */}
      <BaseDataTable
        data={apiKeysStore.apiKeys}
        columns={columns}
        paginationLogic={apiKeysStore.pagination}
      />
      {/* Outlet for modal windows */}
      <Outlet />
    </div>
  )
})

export default ApiKeysTable
