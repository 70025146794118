import { useEffect, useRef } from 'react'

// eslint-disable-next-line @typescript-eslint/ban-types
const useDebounce = (
  callback: (...args: any) => any,
  delay: number
): ((...args: any[]) => void) => {
  const timeoutRef = useRef<any>(null)

  useEffect(() => {
    return () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  const debouncedCallback = (...args: any[]): void => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => {
      // eslint-disable-next-line n/no-callback-literal
      callback(...args)
    }, delay)
  }

  return debouncedCallback
}
export default useDebounce
