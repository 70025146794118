import { type ReactElement, type SVGProps } from 'react'

const AlertIcon = (
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGElement> => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="black"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect y="2" width="25" height="21" rx="10.5" />
      <path
        d="M7.452 18.5L8.628 17.36C7.284 16.184 6.576 14.78 6.576 12.92C6.576 11.048 7.284 9.668 8.628 8.48L7.452 7.34C5.88 8.588 4.896 10.484 4.896 12.92C4.896 15.356 5.88 17.252 7.452 18.5ZM11.717 14.096H13.121L13.445 8.228H11.417L11.717 14.096ZM12.425 17.12C13.001 17.12 13.433 16.688 13.433 16.136C13.433 15.548 13.001 15.116 12.425 15.116C11.861 15.116 11.441 15.548 11.441 16.136C11.441 16.688 11.861 17.12 12.425 17.12ZM17.395 18.5C18.979 17.252 19.963 15.356 19.963 12.92C19.963 10.484 18.979 8.588 17.395 7.34L16.231 8.48C17.575 9.668 18.271 11.048 18.271 12.92C18.271 14.78 17.575 16.184 16.231 17.36L17.395 18.5Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default AlertIcon
