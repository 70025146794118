import DepositLimit from '../../ui/DepositLimit/DepositLimit'
import Input from '../../ui/Input/Input'
import Button, { EButtonType } from '../../ui/Button'
import styles from './NewFiatWithdrawAccountForm.module.scss'
import { useNavigate, useParams } from 'react-router-dom'
import { useStore } from '../../models/Store'
import { useForm } from 'react-hook-form'
import useFormStepper from 'src/utils/hooks/useFormStepper'
import OTPCodeInput from '../Modals/ApiKeysPageModals/OTPCodeInput/OTPCodeInput'

type FormInputs = {
  account_name: string
  bank_name: string
  iban: string
  bic: string
  tfa_code: string
}

const NewFiatWithdrawAccountForm = (): any => {
  const navigate = useNavigate()
  const { fiatStore, utilityStore } = useStore()
  const params = useParams()
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    control,
    trigger
  } = useForm<FormInputs>({ mode: 'all' })
  const onSubmit = handleSubmit((values: any) => {
    const response = fiatStore.AddWithdrawAccount({
      ...values,
      reference: null
    })
    if (response !== undefined) {
      utilityStore.closeSideBarModal()
    }
    // fiatStore
    //   .AddWithdrawAccount({
    //     ...values,
    //     reference: null
    //   })
    //   .then(() => {
    //     utilityStore.closeSideBarModal()
    //     // navigate(`/balances/fiat/withdraw-fiat/${params.id}`)
    //   })
    //   .catch((e) => {
    //     console.log(e)
    //   })
  })
  const requiredRule = { value: true, message: 'Field is required' }

  const { currentStepElement, moveToNextStep } = useFormStepper([
    <>
      <h3>Add new SEPA bank account</h3>
      <DepositLimit min={10} max={1000} />
      <Input
        wide
        label={'Account name'}
        errors={errors.account_name}
        {...register('account_name', { required: requiredRule })}
      />
      <Input
        wide
        label={'IBAN'}
        errors={errors.iban}
        {...register('iban', { required: requiredRule })}
      />
      <Input
        wide
        label={'Bank name'}
        errors={errors.bank_name}
        {...register('bank_name', { required: requiredRule })}
      />
      <Input
        wide
        label={'BIC'}
        errors={errors.bic}
        {...register('bic', { required: requiredRule })}
      />
      <div className={styles.Row}>
        <Button
          wide
          title={'Add account'}
          onClick={(e) => {
            if (isValid) {
              moveToNextStep()
            } else {
              e.preventDefault()
              void trigger()
            }
          }}
        />
        <Button
          wide
          title={'Cancel'}
          buttonType={EButtonType.SECONDARY}
          onClick={() => {
            // navigate(`/balances/fiat/withdraw-fiat/${params.id}`)
            utilityStore.closeSideBarModal()
          }}
        />
      </div>
    </>,
    <OTPCodeInput
      key={1}
      control={control}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
    />
  ])
  return (
    <div className={styles.NewFiatWithdrawAccountForm}>
      {currentStepElement}
    </div>
  )
}

export default NewFiatWithdrawAccountForm
