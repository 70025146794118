import { useParams } from 'react-router-dom'
import BaseSideBarComponent from '../../components/BaseSideBarComponent/BaseSideBarComponent'
import Payin from '../../assets/images/svg/Payin.svg'
import { colors } from '../../assets/scss/themes'
import type React from 'react'
import NewFiatWithdrawAccountForm from '../../components/NewFiatWithdrawAccountForm/NewFiatWithdrawAccountForm'

const NewFiatWithdrawAccount: React.FC = () => {
  const params = useParams()
  return (
    <BaseSideBarComponent
      headerIcon={<Payin fill={colors.main} stroke={colors.main} />}
      headerTitle={`Withdraw to your ${params.id as string} account`}
      checkTFAEnabled
    >
      <div className="WithdrawFiatPage">
        <NewFiatWithdrawAccountForm />
      </div>
    </BaseSideBarComponent>
  )
}

export default NewFiatWithdrawAccount
