import { observer } from 'mobx-react'
import { Row } from 'react-bootstrap'
import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { useStore } from 'src/models/Store'
import Preloader from 'src/ui/Preloader'
import './UnprotectedPage.scss'
import { colors } from 'src/assets/scss/themes'
import Logo from '../../../assets/images/CitronLogoWhite.svg'

const UnprotectedPage = observer(() => {
  const { authStore } = useStore()
  const location = useLocation()
  const from = location.state?.from !== undefined ? location.state?.from : '/'

  if (authStore.isAuth) {
    return <Navigate to={from} />
  }

  return (
    <>
      {!authStore.isFetched ? (
        <Row
          style={{ height: '100vh' }}
          className={'justify-content-center align-content-center'}
        >
          <Preloader />
        </Row>
      ) : (
        <div className="UnprotectedPage">
          <div className="UnprotectedPage-Logo">
            <Logo fill={colors.main} />
          </div>
          <div className="UnprotectedPage-Outlet">
            <Outlet />
          </div>
        </div>
      )}
    </>
  )
})

export default UnprotectedPage
