import type React from 'react'
import './TopUpFiatPage.scss'
import BaseSideBarComponent from 'src/components/BaseSideBarComponent/BaseSideBarComponent'
import { colors } from 'src/assets/scss/themes'
import Payin from '../../assets/images/svg/Payin.svg'
import { useLocation } from 'react-router-dom'
import AlertIcon from 'src/assets/images/svg/AlertIcon'

const TopUpFiatPage: React.FC = () => {
  const location = useLocation()
  return (
    <BaseSideBarComponent
      headerIcon={<Payin fill={colors.main} stroke={colors.main} />}
      headerTitle={`Deposit to your ${
        location.state.currency as string
      } account`}
    >
      <div className="TopUpFiatPage">
        <p>
          <AlertIcon fill={colors.main} color={colors.bg} /> International money
          transfer in euros within the Single Euro Payments
        </p>
        <ol className="TopUpFiatPage-Requirements">
          <li>123</li>
          <li>TODO REQUIREMENTS</li>
          <li>123</li>
        </ol>
        <h3>Transfer funds to bank account below</h3>
        <div>TODO FIAT TOPUP</div>
      </div>
    </BaseSideBarComponent>
  )
}
export default TopUpFiatPage
