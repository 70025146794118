import type React from 'react'
import { type ReactNode, createContext, useContext, useState } from 'react'

/**
 * This hook is used to open and display a modal with your custom content
 *
 * It has styling restrictions as per design
 */
export const SideBarModal = (): {
  openModal: (content: React.ReactNode) => void
  closeModal: () => void
  isModalDisplayed: boolean
  modalContent: React.ReactNode | null
  /**
   * Header content restricted by design, it should always have an icon and a title
   */
  modalHeaderContent: {
    headerIcon: ReactNode | null
    headerTitle: string | undefined
  }
} => {
  const [modalContent, setModalContent] = useState<React.ReactNode | null>(null)
  const [modalHeaderContent, setModalHeaderContent] = useState<{
    headerIcon: ReactNode | null
    headerTitle: string | undefined
  }>({ headerIcon: null, headerTitle: undefined })
  const [isModalDisplayed, setIsModalDisplayed] = useState<boolean>(false)

  const openModal = (
    content: React.ReactNode,
    headerIcon?: React.ReactNode,
    headerTitle?: string
  ): void => {
    setModalContent(content)
    setModalHeaderContent({ headerIcon, headerTitle })
    setIsModalDisplayed(true)
  }

  const closeModal = (): void => {
    setIsModalDisplayed(false)
  }

  return {
    openModal,
    closeModal,
    isModalDisplayed,
    modalContent,
    modalHeaderContent
  }
}

const ModalContext = createContext<{
  openModal: (
    content: React.ReactNode,
    headerIcon?: React.ReactNode,
    headerTitle?: string
  ) => void
  closeModal: () => void
  isModalDisplayed: boolean
  modalContent: React.ReactNode | null
  modalHeaderContent: {
    headerIcon: ReactNode | null
    headerTitle: string | undefined
  }
}>({
  openModal() {},
  closeModal() {},
  isModalDisplayed: false,
  modalContent: null,
  modalHeaderContent: {
    headerIcon: null,
    headerTitle: undefined
  }
})

/**
 * Use this to open a modal with custom content
 *
 * Modal is restricted to be a sidebar as per design,
 * when closing the modal it will proceed with it's closing animation
 * and unmount itself when it's done
 */
const useSideBarModal = (): React.ContextType<typeof ModalContext> =>
  useContext(ModalContext)

export const ModalProvider = ({
  children
}: {
  children: React.ReactNode
}): JSX.Element => {
  const {
    closeModal,
    openModal,
    modalContent,
    isModalDisplayed,
    modalHeaderContent
  } = SideBarModal()

  return (
    <ModalContext.Provider
      value={{
        closeModal,
        openModal,
        modalContent,
        isModalDisplayed,
        modalHeaderContent
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}
export default useSideBarModal
