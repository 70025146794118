import { type SVGProps, type ReactElement } from 'react'

const CloseIcon = (
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGElement> => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      stroke="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_50_4428)">
        <path d="M2 2L23 23" strokeWidth="3" strokeLinecap="round" />
        <path d="M23 2L2 23" strokeWidth="3" strokeLinecap="round" />
      </g>
      <defs>
        <clipPath id="clip0_50_4428">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default CloseIcon
