import type React from 'react'
import '../WithdrawPage.scss'
import Input from 'src/ui/Input/Input'
import InfoIcon from 'src/assets/images/svg/InfoIcon'
import { colors } from 'src/assets/scss/themes'
import Button, { EButtonType } from 'src/ui/Button'
import {
  type UseFormSetValue,
  type FieldError,
  type UseFormRegister
} from 'react-hook-form'
import { type BaseSyntheticEvent } from 'react'
import DepositLimit from 'src/ui/DepositLimit/DepositLimit'

interface WithdrawEnterAmountProps {
  onChangeAddress: () => any
  hookFormRegister: UseFormRegister<{ amount: string }>
  inputErrors: FieldError | undefined
  onSubmit: (
    e?: BaseSyntheticEvent<object, any, any> | undefined
  ) => Promise<void>
  selectedWalletName: string
  selectedWalletDetails: string
  isFormSubmitting: boolean
  setValue: UseFormSetValue<{
    amount: string
  }>
  selectedCoin: string
  feePercent: string
  feeAbsoluteAmount: string
  availableBalance: string
  depositLimitMin: number
  depositLimitMax: number
}

const WithdrawEnterAmount: React.FC<WithdrawEnterAmountProps> = ({
  onChangeAddress,
  hookFormRegister,
  inputErrors,
  onSubmit,
  selectedWalletName,
  selectedWalletDetails,
  isFormSubmitting,
  setValue,
  selectedCoin,
  feePercent,
  feeAbsoluteAmount,
  availableBalance,
  depositLimitMin,
  depositLimitMax
}) => {
  const maximumWithdrawAmountAfterFees =
    parseInt(availableBalance) - parseInt(feeAbsoluteAmount)

  const handlePercentAmountSelect = async (percent: number): Promise<void> => {
    const calculatedPercentFromAmount =
      (maximumWithdrawAmountAfterFees / 100) * percent
    setValue('amount', calculatedPercentFromAmount.toFixed(2).toString())
  }

  return (
    <div className="WithdrawPage">
      <h3>Select amount for withdraw</h3>
      <span className="WithdrawPage-BalanceInfo">
        <InfoIcon fillbackground={colors.main} fill={colors.mainBackground} />{' '}
        Available balance: {availableBalance} {selectedCoin}
      </span>
      <DepositLimit min={depositLimitMin} max={depositLimitMax} />
      <Input
        label="Amount"
        errors={inputErrors}
        {...hookFormRegister('amount', {
          // min: {
          //   value: 0.000001,
          //   message: 'Amount is less than the minimum allowed'
          // },
          // max: {
          //   value: maximumWithdrawAmountAfterFees,
          //   message: 'Amount is greater than available to withdraw'
          // },
          pattern: {
            value: /^(0|[1-9]\d*)((\.|,)\d+)?$/,
            message: 'Please enter a valid amount'
          }
        })}
      />
      <div className="WithdrawPage-PercentButtons">
        <Button
          title="25%"
          onClick={() => {
            void handlePercentAmountSelect(25)
          }}
        />
        <Button
          title="50%"
          onClick={() => {
            void handlePercentAmountSelect(50)
          }}
        />
        <Button
          title="75%"
          onClick={() => {
            void handlePercentAmountSelect(75)
          }}
        />
        <Button
          title="100%"
          onClick={() => {
            void handlePercentAmountSelect(100)
          }}
        />
      </div>
      <div className="WithdrawPage-FeeInfo">
        Fee
        <p className="WitdrawPage-FeeInfo__feeValue">
          {feePercent}% {selectedCoin}
        </p>
      </div>
      <div>
        Wallet
        <div className="WithdrawPage-AddressSelect">
          {selectedWalletName}
          <div className="WithdrawPage-AddressSelect__slug">
            {selectedWalletDetails}
          </div>
        </div>
      </div>
      <div className="WithdrawPage-ButtonGroup">
        <Button title="Submit" onClick={onSubmit} disabled={isFormSubmitting} />
        <Button
          title="Change address"
          buttonType={EButtonType.SECONDARY}
          onClick={() => {
            onChangeAddress()
          }}
        />
      </div>
    </div>
  )
}
export default WithdrawEnterAmount
