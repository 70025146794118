import styles from './DepositLimit.module.scss'
export interface DepositLimitProps {
  min: number
  max: number
}

const DepositLimit = (props: DepositLimitProps): JSX.Element => {
  return (
    <div className={styles.DepositLimit}>
      <ul className={styles.List}>
        <li>Minimum deposit amount {props.min}</li>
        <li>Maximum deposit amount {props.max}</li>
      </ul>
    </div>
  )
}

export default DepositLimit
