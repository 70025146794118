import { type ReactElement, type SVGProps } from 'react'

const ArrowIcon = (
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGElement> => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M18.3536 12.6464C18.5488 12.8417 18.5488 13.1583 18.3536 13.3536L15.1716 16.5355C14.9763 16.7308 14.6597 16.7308 14.4645 16.5355C14.2692 16.3403 14.2692 16.0237 14.4645 15.8284L17.2929 13L14.4645 10.1716C14.2692 9.97631 14.2692 9.65973 14.4645 9.46447C14.6597 9.2692 14.9763 9.2692 15.1716 9.46447L18.3536 12.6464ZM7 13.5C6.72386 13.5 6.5 13.2761 6.5 13C6.5 12.7239 6.72386 12.5 7 12.5L7 13.5ZM18 13.5L7 13.5L7 12.5L18 12.5L18 13.5Z" />
    </svg>
  )
}
export default ArrowIcon
