import type React from 'react'
import { observer } from 'mobx-react'
import useFormStepper from 'src/utils/hooks/useFormStepper'
import BaseSideBarComponent from 'src/components/BaseSideBarComponent/BaseSideBarComponent'
import DotsMenuIcon from 'src/assets/images/svg/DotsMenuIcon'
import { useStore } from 'src/models/Store'
import Input from 'src/ui/Input/Input'
import Button, { EButtonType } from 'src/ui/Button'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import OTPCodeInput from 'src/components/Modals/ApiKeysPageModals/OTPCodeInput/OTPCodeInput'

type FormInputs = {
  account_name: string
  tfa_code: string
}

const FiatAddressesEdit: React.FC = observer(() => {
  const { fiatStore, utilityStore } = useStore()
  const { id } = useParams() as { id: string }

  const currentAddress = fiatStore.savedWithdrawAccounts.find(
    (account) => account.id === parseInt(id)
  )

  const {
    register,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    control
  } = useForm<FormInputs>({
    defaultValues: {
      account_name: currentAddress?.account_name ?? ''
    },
    mode: 'all'
  })

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    const { account_name, tfa_code } = data
    const response = await fiatStore.EditFiatBankAccount({
      id,
      account_name,
      tfa_code
    })

    if (response !== undefined) {
      utilityStore.closeSideBarModal()
    }
  }

  const { currentStepElement, moveToNextStep } = useFormStepper([
    <form key={0}>
      <div className="FiatAddressesPage-Modal">
        <h3>Edit Address</h3>
        <Input
          wide
          label="Account name"
          errors={errors.account_name}
          {...register('account_name', {
            required: { value: true, message: 'Field is required' },
            maxLength: {
              value: 128,
              message: 'Maximum lenght amount allowed is 128'
            }
          })}
        />
        <div className="ButtonGroup">
          <Button
            type="submit"
            title="Save"
            onClick={(e) => {
              if (isValid) {
                moveToNextStep()
              } else {
                // form tries to submit, prevents default behavior
                e.preventDefault()
              }
            }}
          />
          <Button
            title="Cancel"
            type="button"
            buttonType={EButtonType.SECONDARY}
            onClick={() => {
              utilityStore.closeSideBarModal()
            }}
          />
        </div>
      </div>
    </form>,
    <OTPCodeInput
      key={1}
      control={control}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
    />
  ])
  return (
    <BaseSideBarComponent
      checkTFAEnabled
      headerTitle="Edit Address"
      headerIcon={<DotsMenuIcon />}
    >
      {currentStepElement}
    </BaseSideBarComponent>
  )
})
export default FiatAddressesEdit
