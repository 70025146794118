import SEPA from '../../assets/images/svg/SEPA.svg'
import Arrow from '../../assets/images/svg/Arrow.svg'
import styles from './WithdrawAccount.module.scss'
export interface WithdrawAccountProps {
  name: string
  iban: string
  onClick: () => void
}
const WithdrawAccount = (props: WithdrawAccountProps): any => {
  return (
    <div className={styles.WithdrawAccount} onClick={props.onClick}>
      <div className={styles.View}>
        <SEPA style={{ minWidth: 40 }} />
        <div className={styles.Info}>
          <div className={styles.Name}>{props.name}</div>
          <div className={styles.IBAN}>{props.iban}</div>
        </div>
      </div>
      <Arrow style={{ minWidth: 25 }} />
    </div>
  )
}

export default WithdrawAccount
