import type Store from './Store'
import { makeAutoObservable } from 'mobx'

export enum EAlertTypes {
  'ERROR' = 'error',
  'SUCCESS' = 'success',
  'WARNING' = 'warning',
  'INFO' = 'info'
}

export type TAlert = {
  id: string | number
  title: string
  content: string
  timeout: number
  type: EAlertTypes
}

export type TAlertList = Record<string, TAlert>

class AlertsStore {
  constructor(private readonly rootStore: Store) {
    this.rootStore = rootStore
    makeAutoObservable<AlertsStore, 'rootStore'>(this, { rootStore: false })
  }

  list: TAlertList = {}

  addAlert(alert: TAlert): void {
    this.list[alert.id] = alert
    setTimeout(() => {
      this.removeAlert(alert.id)
    }, alert.timeout)
  }

  removeAlert(id: string | number): void {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    if (this.list[id] !== undefined) delete this.list[id]
  }
}

export default AlertsStore
