import type React from 'react'
import './ExchangeModal.scss'
import { type IQouta } from './ExchangeModal'

type ExchangeModalQuotaProps = {
  quota: IQouta
}

const ExchangeModalQuota: React.FC<ExchangeModalQuotaProps> = ({ quota }) => {
  return (
    <div className="ExchangeModal-Rates">
      <div>
        <p>
          You exchange
          <span>
            {quota.from_amount} {quota.from_coin}
          </span>
        </p>
        <p>
          Fee
          <span>
            {quota.fee_amount} {quota.fee_coin}
          </span>
        </p>
        <p>
          Amount to exchange
          <span>
            {quota.from_amount} {quota.from_coin}
          </span>
        </p>
        <p>
          Exchange rate
          <span>{quota.exchange_rate}</span>
        </p>
        <p>
          You are getting
          <span>
            {quota.to_amount} {quota.to_coin}
          </span>
        </p>
      </div>
    </div>
  )
}
export default ExchangeModalQuota
