/* eslint-disable @typescript-eslint/explicit-function-return-type */
import MenuButton from './MenuButton'

export type TMenuItems = {
  title: string
  link: string
}
export type TMenuProps = {
  items: TMenuItems[]
  type?: 'main' | 'pills' | 'filter' | 'inModal'
  end?: boolean
}

const MainMenu = ({ items, type = 'main', end }: TMenuProps) => {
  return (
    <div className={`menu-wrapper ${type}`}>
      {items.map(({ title, link }) => {
        return <MenuButton key={link} title={title} link={link} end={end} />
      })}
    </div>
  )
}

export default MainMenu
