import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { useStore } from '../../models/Store'
import QRCode from 'react-qr-code'
import { Col, Row } from 'react-bootstrap'
import Button, { EButtonType } from '../../ui/Button'
import { useNavigate } from 'react-router-dom'
import Preloader from '../../ui/Preloader'
import { type SubmitHandler, useForm } from 'react-hook-form'
import BaseSideBarComponent from 'src/components/BaseSideBarComponent/BaseSideBarComponent'
import { OTPInput } from 'src/ui/Input/OtpInput'

interface IFormInput {
  otp_code: string
}

const Setup2fa = observer(() => {
  const { userStore } = useStore()
  const navigate = useNavigate()
  const {
    handleSubmit,
    control,
    formState: { isSubmitting }
  } = useForm<IFormInput>()

  const onSubmitDisableEnable2FA: SubmitHandler<IFormInput> = async (data) => {
    const response = await userStore.tfa.setOtpStatus({
      otp_code: data.otp_code,
      is_enabled: !userStore.user.tfa_enabled
    })
    if (response !== undefined) {
      navigate('../..')
    }
  }

  const { tfa_enabled } = userStore.user
  const displayInstructions = {
    isTFAEnabled: tfa_enabled
      ? 'Currently you have two-factor authentication enabled!'
      : 'Install the Google Authenticator app on your mobile device',
    disableOrEnable: tfa_enabled
      ? 'To disable it enter the code generated in your Google Authenticator below.' +
        ' If you proceed with disabling 2FA you will have to generate a new authenticator with a QR code!'
      : 'Scan this QR code with Google Authenticator '
  }

  useEffect(() => {
    if (tfa_enabled) {
      return
    }
    void userStore.tfa.getOneTimePasswordQRCode()
  }, [])
  if (userStore.tfa.isLoading) return <Preloader />
  if (userStore.tfa.hasErrors)
    return <p>Something went wrong... Please try again later</p>

  return (
    <BaseSideBarComponent headerTitle="Two-Factor Authentication">
      <form>
        <Row className={'mb-3 mt-5'}>
          <Col>
            <p className={'text-center'}>{displayInstructions.isTFAEnabled}</p>
          </Col>
        </Row>
        <Row className={'justify-content-center align-content-center mb-4'}>
          <p className={'text-center'}>{displayInstructions.disableOrEnable}</p>
          {!tfa_enabled && (
            <div
              style={{
                background: 'white',
                padding: 20,
                borderRadius: 20,
                width: 220
              }}
            >
              <QRCode
                value={userStore.tfa.otp_qr_url}
                style={{ width: 180, height: 180 }}
              />
            </div>
          )}
        </Row>
        <Row className={'mb-3 text-center'}>
          <Col>
            <OTPInput noAutoFocus control={control} name="otp_code" />
            <Button
              buttonType={EButtonType.PRIMARY}
              onClick={handleSubmit(onSubmitDisableEnable2FA)}
              isLoading={isSubmitting}
              title={userStore.user.tfa_enabled ? 'Disable' : 'Enable'}
              style={{ width: '100%', marginTop: 50 }}
              type="submit"
            />
          </Col>
        </Row>
      </form>
    </BaseSideBarComponent>
  )
})

export default Setup2fa
