import { type FC } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useSideBarModal from '../../hooks/useSideBarModal'

export type TMenuButtonProps = {
  title: string
  link: string
  active?: boolean
  end?: boolean
}

const MenuButton: FC<TMenuButtonProps> = ({
  title,
  link,
  active = false,
  end = false
}) => {
  const { t } = useTranslation()
  const { closeModal } = useSideBarModal()
  return (
    <NavLink
      to={link}
      onClick={closeModal}
      className={({ isActive }) =>
        isActive ? 'menu-button active' : 'menu-button'
      }
      end={end}
    >
      {t(title)}
    </NavLink>
  )
}

export default MenuButton
