import type React from 'react'
import './ModalOTPInput.scss'
import { type Control } from 'react-hook-form'
import { OTPInput } from 'src/ui/Input/OtpInput'
import Button, { EButtonType } from 'src/ui/Button'
import { type BaseSyntheticEvent } from 'react'
import { useNavigate } from 'react-router-dom'

interface ModalOTPInputProps {
  /**
   * tfa_code will be used on form when using this component
   */
  control: Control<any, any>
  // TODO: Change this type later
  onSubmit: (
    e?: BaseSyntheticEvent<object, any, any> | undefined
  ) => Promise<void>
  /**
   * Disables the submit and cancel button while the form is sending a request
   *
   * Best used with react-hook-form's formState: { isSubmitting } prop
   */
  isSubmitting: boolean
  // custom functionality for cancel button
  // onCancel?:
}

/**
 * Used in sidebar modals for 2FA authentication, needs a control prop from your react-hook-form
 */
const ModalOTPInput: React.FC<ModalOTPInputProps> = ({
  control,
  onSubmit,
  isSubmitting
}) => {
  const navigate = useNavigate()

  return (
    <div className="ModalOTPInput">
      <h2>2FA authentication</h2>
      <form>
        <OTPInput
          control={control}
          name="tfa_code"
          label={
            'Please enter the sign-in 2FA code from your authenticator app'
          }
        />
        <div className="ModalOTPInput-ButtonGroup">
          <Button
            title="Submit"
            type="submit"
            onClick={onSubmit}
            disabled={isSubmitting}
          />
          <Button
            title="Cancel"
            buttonType={EButtonType.SECONDARY}
            disabled={isSubmitting}
            onClick={() => {
              navigate('../..')
            }}
          />
        </div>
      </form>
    </div>
  )
}
export default ModalOTPInput
