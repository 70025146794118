import type React from 'react'
import './SideBarModal.scss'
import useSideBarModal from 'src/hooks/useSideBarModal'
import { CSSTransition } from 'react-transition-group'
import { useRef } from 'react'
import CloseIcon from 'src/assets/images/svg/CloseIcon'

const SideBarModal: React.FC = () => {
  const { isModalDisplayed, modalContent, closeModal, modalHeaderContent } =
    useSideBarModal()
  const nodeRef = useRef(null)

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={isModalDisplayed}
      classNames="slide-left"
      timeout={300}
      appear
      mountOnEnter
      unmountOnExit
    >
      <div className="SideBarModal" ref={nodeRef}>
        <div
          className="SideBarModal-Overlay"
          onClick={() => {
            closeModal()
          }}
        />
        <div className="SideBarModal-Content">
          {modalHeaderContent.headerTitle !== undefined && (
            <div className="SideBarModal-Header">
              {modalHeaderContent?.headerIcon}
              {modalHeaderContent?.headerTitle}
              <CloseIcon
                className="CloseIcon"
                onClick={() => {
                  closeModal()
                }}
              />
            </div>
          )}
          {modalContent}
        </div>
      </div>
    </CSSTransition>
  )
}
export default SideBarModal
