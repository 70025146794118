import type React from 'react'
import Lock from '../../assets/images/svg/Lock.svg'
import Info from '../../assets/images/svg/informationlineforheading.svg'
import GirlWithPhone from '../../assets/images/GirlWithPhone.png'
import { colors } from 'src/assets/scss/themes'
import Button, { EButtonType } from 'src/ui/Button'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from 'src/models/Store'

const EnableTFASidebarComponent: React.FC = observer(() => {
  const navigate = useNavigate()
  const { utilityStore } = useStore()
  return (
    <>
      <div
        style={{
          paddingTop: 40,
          paddingBottom: 20,
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          gap: 20
        }}
      >
        <h4>Two-Factor Authentication</h4>
        <div>
          <Info fill={colors.error} style={{ marginRight: 20 }} />
          <span style={{ color: colors.main }}>
            You need to enable 2FA for withdrawals!
          </span>
        </div>
        <ul
          style={{
            background: '#474747',
            borderRadius: 10,
            textAlign: 'start',
            fontSize: 'small',
            paddingBlock: 15
          }}
        >
          <li>Go to security settings</li>
          <li>Enable two-factor authentication</li>
          <li>Make withdrawal safely</li>
        </ul>
        <Button
          icon={<Lock stroke={colors.main} />}
          title={'Enable 2FA now!'}
          onClick={() => {
            navigate('/profile/security')
            utilityStore.closeSideBarModal()
          }}
          buttonType={EButtonType.PRIMARY}
          style={{ width: '100%' }}
        />
      </div>
      <img
        src={GirlWithPhone}
        style={{ maxWidth: '100%', position: 'absolute', bottom: 0 }}
      />
    </>
  )
})
export default EnableTFASidebarComponent
