import type React from 'react'
import { observer } from 'mobx-react'
import WithdrawIcon from 'src/assets/images/svg/WithdrawIcon'
import { colors } from 'src/assets/scss/themes'
import BaseSideBarComponent from 'src/components/BaseSideBarComponent/BaseSideBarComponent'
import NewFiatWithdrawAccountForm from 'src/components/NewFiatWithdrawAccountForm/NewFiatWithdrawAccountForm'

const FiatAddressesAdd: React.FC = observer(() => {
  return (
    <BaseSideBarComponent
      headerIcon={<WithdrawIcon stroke={colors.main} />}
      headerTitle={'Add new bank account'}
      checkTFAEnabled
    >
      <div className="WithdrawFiatPage">
        <NewFiatWithdrawAccountForm />
      </div>
    </BaseSideBarComponent>
  )
})
export default FiatAddressesAdd
