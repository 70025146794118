import { observer } from 'mobx-react'
import { useStore } from '../../models/Store'
import { type ReactNode, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import Preloader from '../../ui/Preloader'
import BaseSideBarComponent from 'src/components/BaseSideBarComponent/BaseSideBarComponent'
import { colors } from 'src/assets/scss/themes'
import Payin from '../../assets/images/svg/Payin.svg'
import moment from 'moment'
import Button, { EButtonSize } from 'src/ui/Button'

const TransactionInfo = observer(() => {
  const { transactionStore, utilityStore } = useStore()
  const location = useLocation()
  const params = useParams()
  useEffect(() => {
    void transactionStore.getTransactionInfo(location.state?.id ?? params.id)
  }, [])

  const {
    currentTransaction: {
      transaction: {
        amount,
        amount_with_commission,
        coin,
        created,
        foreign_id,
        id,
        network,
        network_fee,
        status,
        txid,
        txid_url,
        type,
        commission_amount
      }
    }
  } = transactionStore

  if (transactionStore.currentTransactionError) return <p>Error</p>
  if (transactionStore.currentTransactionLoading) return <Preloader />
  const created_at = moment(created).format('DD.MM.YY, hh:mm:ss')

  const returnTransactionExchange = (): JSX.Element | null => {
    if (transactionStore.currentTransaction.exchange_calculation === null) {
      return null
    }
    const {
      exchange_rate,
      exchanged_amount,
      from_currency,
      original_amount,
      to_exchange_currency
    } = transactionStore.currentTransaction.exchange_calculation
    return (
      <>
        <RenderRow title={'Base currency'} value={to_exchange_currency} />
        <RenderRow
          title={'Exchange rate'}
          value={`${to_exchange_currency} = ${exchange_rate} ${from_currency}`}
        />
        <RenderRow title={'Original amount'} value={original_amount} />
        <RenderRow title={'Exchanged amount'} value={exchanged_amount} />
        <RenderRow title={'Fee'} value={commission_amount} />
      </>
    )
  }
  return (
    <BaseSideBarComponent
      headerIcon={<Payin fill={colors.main} stroke={colors.main} />}
      headerTitle={`${transactionStore.currentTransaction.transaction.type} +${amount} ${coin}`}
    >
      <Row className={'tr-info'} style={{ marginTop: 20 }}>
        <RenderRow title={'ID'} value={id} />
        <RenderRow title={'Created at'} value={created_at} />
        <RenderRow title={'Type'} value={type} />
        <RenderRow title={'Status'} value={status} />
        <RenderRow title={'Foreign id'} value={foreign_id ?? 'None'} />

        {transactionStore.currentTransaction.exchange_calculation === null ? (
          <>
            <RenderRow title={'Amount'} value={`${amount} ${coin}`} />
            <RenderRow title={'Fee'} value={`${network_fee} ${coin}`} />
            <RenderRow
              title={'Amount with commission'}
              value={`${amount_with_commission} ${coin}`}
            />
            <RenderRow
              title={'TxId'}
              value={
                <a
                  href={txid_url}
                  target="_blank"
                  className={'link text-truncate'}
                  rel="noreferrer"
                >
                  {txid}
                </a>
              }
            />
            <RenderRow title={'Network'} value={network} />
            <RenderRow title={'Coin'} value={coin} />
          </>
        ) : (
          returnTransactionExchange()
        )}
        <RenderRow
          title={'Confirmation'}
          value={
            <Button
              size={EButtonSize.SM}
              title="Download"
              icon={<Payin stroke={colors.bg} />}
              onClick={() => {
                transactionStore.downloadTransactionPDF(id)
              }}
            />
          }
        />
        <Col xs={12}>
          <Button
            title="Close"
            style={{ marginTop: 'auto' }}
            wide
            onClick={() => {
              utilityStore.closeSideBarModal()
            }}
          />
        </Col>
      </Row>
    </BaseSideBarComponent>
  )
})

type TRenderRow = {
  title: string
  value: string | ReactNode
}
const RenderRow = observer(({ title, value }: TRenderRow) => {
  return (
    <Row style={{ marginBottom: 10 }}>
      <Col xs={6}>
        <p>{title}</p>
      </Col>
      <Col xs={6}>
        <p>{value}</p>
      </Col>
    </Row>
  )
})

export default TransactionInfo
