import { type SVGProps, type ReactElement } from 'react'

const DoubleArrowIcon = (
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGElement> => {
  return (
    <svg
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.94173 1.0625L9.94173 24.9375L1.9834 16.9792"
        stroke="#F3FF00"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0581 1.0625L14.0581 24.9375L22.0164 16.9792"
        stroke="#F3FF00"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export default DoubleArrowIcon
