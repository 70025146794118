import './App.scss'
import { Container, ThemeProvider } from 'react-bootstrap'
import Router from './router/router'
import Store, { StoreProvider } from './models/Store'
import { ModalProvider } from './hooks/useSideBarModal'

export const store = new Store()

function App(): JSX.Element {
  return (
    <ThemeProvider breakpoints={['xl', 'lg', 'md', 'sm', 'xs']}>
      <StoreProvider store={store}>
        <ModalProvider>
          <Container fluid>
            <Router />
          </Container>
        </ModalProvider>
      </StoreProvider>
    </ThemeProvider>
  )
}

export default App
