import type Store from './Store'
import { action, makeAutoObservable } from 'mobx'

class AuthStore {
  public isAuth: boolean = false
  public isFetched: boolean = false

  constructor(private readonly rootStore: Store) {
    this.rootStore = rootStore
    makeAutoObservable<AuthStore, 'rootStore'>(this, {
      rootStore: false,
      setAuth: action,
      checkResponseStatus: action
    })
  }

  public setAuth(status: boolean): void {
    this.isAuth = status
    this.isFetched = true
  }

  public checkResponseStatus(res: { status: number }): void {
    if (this.rootStore.userStore.tfaStatusChecked) {
      this.setAuth(res.status !== 401)
    } else {
      this.setAuth(false)
    }
  }
}

export default AuthStore
