import type React from 'react'
import './ProfileChangePassword.scss'
import { useStore } from 'src/models/Store'
import { type SubmitHandler, useForm } from 'react-hook-form'
import Input from 'src/ui/Input/Input'
import Button from 'src/ui/Button'
import useFormStepper from 'src/utils/hooks/useFormStepper'
import OTPCodeInput from 'src/components/Modals/ApiKeysPageModals/OTPCodeInput/OTPCodeInput'
import { colors } from 'src/assets/scss/themes'
import { observer } from 'mobx-react'
import { useEffect } from 'react'
import BaseSideBarComponent from 'src/components/BaseSideBarComponent/BaseSideBarComponent'
import LockIcon from 'src/assets/images/svg/LockIcon'

type FormInputs = {
  old_password: string
  new_password: string
  re_new_password: string
  tfa_code: string
}

const ProfileChangePassword: React.FC = observer(() => {
  const { userStore, utilityStore } = useStore()
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitting },
    getValues,
    trigger
  } = useForm<FormInputs>({
    criteriaMode: 'all',
    mode: 'onChange',
    defaultValues: { tfa_code: '' }
  })

  useEffect(() => {
    void trigger()
  }, [])

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    const response = await userStore.profilePasswordChange(data)
    if (response !== undefined) {
      utilityStore.closeSideBarModal()
    }
  }

  const ChangePasswordFormGroup = (
    <div>
      <h3>Change password</h3>
      <Input
        wide
        type="password"
        label="Old password"
        {...register('old_password', { required: true })}
      />
      <Input
        wide
        type="password"
        label="New password"
        {...register('new_password', {
          required: true,
          minLength: 8,
          validate: {
            latinOnly: (value) =>
              /^[A-Za-z0-9!@#$%^&*()'"_+{}[\]:;<>,.?~\\/-]+$/.test(value),
            specialCharacters: (value) =>
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*?&#:;,+_.^()-]).+$/.test(
                value
              )
          }
        })}
      />
      <Input
        wide
        type="password"
        label="Confirm new password"
        {...register('re_new_password', {
          validate: (value) =>
            value === getValues('new_password') || 'Passwords must match'
        })}
      />

      <ul
        style={{
          listStyle: 'none',
          padding: 0
        }}
      >
        <li
          className={`PasswordRequirements ${
            !isValid && (errors.new_password?.types?.latinOnly as boolean)
              ? 'Incomplete'
              : 'Complete'
          }`}
        >
          <p>Letters of the Latin alphabet only</p>
        </li>
        <li
          className={`PasswordRequirements ${
            !isValid &&
            ((errors.new_password?.types?.minLength as boolean) ||
              (errors.new_password?.types?.required as boolean))
              ? 'Incomplete'
              : 'Complete'
          }`}
        >
          <p>Passwords must be at least 8 characters long</p>
        </li>
        <li
          className={`PasswordRequirements ${
            !isValid &&
            (errors.new_password?.types?.specialCharacters as boolean)
              ? 'Incomplete'
              : 'Complete'
          }`}
        >
          <p>One capital letter, number and special character</p>
        </li>

        {errors.re_new_password?.message !== undefined && (
          <p style={{ color: colors.error }}>
            {errors.re_new_password.message}
          </p>
        )}
      </ul>

      <Button
        title="Change password"
        disabled={!isValid}
        onClick={() => {
          moveToNextStep()
        }}
      />
    </div>
  )

  const { currentStepElement, moveToNextStep } = useFormStepper([
    ChangePasswordFormGroup,
    <OTPCodeInput
      key={1}
      control={control}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
      onCancel={() => {
        utilityStore.closeSideBarModal()
      }}
    />
  ])

  return (
    <BaseSideBarComponent
      headerIcon={<LockIcon />}
      headerTitle="Password"
      checkTFAEnabled
    >
      <div className="ProfileChangePassword">{currentStepElement}</div>
    </BaseSideBarComponent>
  )
})
export default ProfileChangePassword
