import { useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import Button from '../../ui/Button'
import { useStore } from '../../models/Store'
import { useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'
import Payin from '../../assets/images/svg/Payin.svg'
import { colors } from '../../assets/scss/themes'
import BaseSideBarComponent from '../../components/BaseSideBarComponent/BaseSideBarComponent'
import useFormStepper from 'src/utils/hooks/useFormStepper'
import WithdrawEnterAmount from '../WithdrawPage/WithdrawSteps/WithdrawEnterAmount'

const FiatPayout = observer(() => {
  const {
    getValues,
    register,
    setValue,
    formState: { errors, isSubmitting }
  } = useForm<{ amount: string }>()
  const params = useParams<{ account: string; id: string }>()
  const { fiatStore, balancesStore } = useStore()
  const navigate = useNavigate()
  const [transactionStatus, setTransactionStatus] = useState<
    'success' | 'error' | null
  >(null)

  const goToHome = (): void => {
    navigate('/')
  }

  const currentAccount = fiatStore.savedWithdrawAccounts.find(
    (account) => account.id.toString() === params.account
  )
  const currentCoin = balancesStore.findBalanceItem('coin', params.id as string)

  if (currentAccount === undefined) {
    return <div>No account found</div>
  }
  if (currentCoin === undefined) {
    return <div>No matching currency found</div>
  }

  const onSubmit = async (): Promise<void> => {
    const data = fiatStore.savedWithdrawAccounts.find(
      (e) => e.id.toString() === params.account
    )
    try {
      if (data === undefined) {
        return
      }
      const { account_name, iban, ...rest } = data
      const res = await fiatStore.Payout({
        ...rest,
        owner_name: account_name,
        customer_purse: iban,
        ...getValues()
      })
      moveToNextStep()
      if (res === undefined || !res.success) throw new Error('error')
      setTransactionStatus('success')
    } catch (e) {
      setTransactionStatus('error')
    }
  }

  const { moveToNextStep, currentStepElement } = useFormStepper([
    <div className="WithdrawFiatPage" key={0}>
      <WithdrawEnterAmount
        hookFormRegister={register}
        availableBalance={currentCoin.balance}
        selectedCoin={currentCoin.coin}
        selectedWalletName={currentAccount.account_name}
        selectedWalletDetails={currentAccount.iban}
        feeAbsoluteAmount={'0'}
        feePercent={'0'}
        inputErrors={errors.amount}
        setValue={setValue}
        isFormSubmitting={isSubmitting}
        onSubmit={onSubmit}
        onChangeAddress={() => {
          navigate(`/balances/fiat/withdraw-fiat/${currentCoin.coin}`)
        }}
        depositLimitMin={fiatStore.withdrawLimits.min}
        depositLimitMax={fiatStore.withdrawLimits.max}
      />
    </div>,
    <div className="WithdrawFiatPage" key={1}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 20
        }}
      >
        {transactionStatus === 'success' ? (
          <h3>Your payment is being processed</h3>
        ) : (
          <h3>Error processing your requrest</h3>
        )}
        <Button wide title={'OK'} onClick={goToHome} />
      </div>
    </div>
  ])

  return (
    <BaseSideBarComponent
      headerIcon={<Payin fill={colors.main} stroke={colors.main} />}
      headerTitle={`Withdraw to your ${params.id as string} account`}
    >
      {currentStepElement}
    </BaseSideBarComponent>
  )
})

export default FiatPayout
