import { observer } from 'mobx-react'
import { useStore } from '../../models/Store'
import { useEffect } from 'react'
import { Col, Row, Table } from 'react-bootstrap'
import FeesFilters from './FeesFilters'
import { Outlet } from 'react-router-dom'
import Preloader from '../../ui/Preloader'

const FeesPage = observer(() => {
  const { feesStore } = useStore()
  if (feesStore.isLoading && !feesStore.isFetched) return <Preloader />
  return (
    <Col>
      <Row
        style={{
          alignItems: 'center',
          marginBlock: 20
        }}
      >
        <Col xs={'auto'}>
          <FeesFilters />
        </Col>
      </Row>
      <Outlet />
    </Col>
  )
})

const B2b = observer(() => {
  const { feesStore } = useStore()
  useEffect(() => {
    feesStore.filters.setSearchFields([
      { field: 'commission_type', value: 'b2b' }
    ])
  }, [])

  return (
    <>
      <Table className={'citron-colors'} responsive>
        <thead>
          <tr>
            <th>Currency</th>
            <th>Top up Fee</th>
            <th>Min Amount Fee</th>
            <th>Exchange Fee</th>
            <th>Min Amount Fee</th>
            <th>Settlement Fee</th>
            <th>Min Amount Fee</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(feesStore.list.b2b).map((key) => {
            return (
              <tr key={key}>
                <td>{key}</td>
                <td>
                  {feesStore.list.b2b[key].top_up_deposit?.percent_value}%
                </td>
                <td>
                  {feesStore.list.b2b[key].top_up_deposit?.min_amount}{' '}
                  {
                    feesStore.list.b2b[key].exchange_withdraw
                      .min_amount_coin_name
                  }
                </td>
                <td>
                  {feesStore.list.b2b[key].exchange_withdraw?.percent_value}%
                </td>
                <td>
                  {feesStore.list.b2b[key].exchange_withdraw?.min_amount}{' '}
                  {
                    feesStore.list.b2b[key].exchange_withdraw
                      .min_amount_coin_name
                  }
                </td>
                <td>
                  {feesStore.list.b2b[key].settlement_withdraw?.percent_value}%
                </td>
                <td>
                  {feesStore.list.b2b[key].settlement_withdraw?.min_amount}{' '}
                  {
                    feesStore.list.b2b[key].settlement_withdraw
                      ?.min_amount_coin_name
                  }
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </>
  )
})
const C2b = observer(() => {
  const { feesStore } = useStore()
  useEffect(() => {
    feesStore.filters.setSearchFields([
      { field: 'commission_type', value: 'c2b' }
    ])
  }, [])

  return (
    <>
      <Table className={'citron-colors'} responsive>
        <thead>
          <tr>
            <th>Currency</th>
            <th>Deposit with exchange</th>
            <th>Min Amount Fee</th>
            <th>Deposit Fee</th>
            <th>Min Amount Fee</th>
            <th>Payout with exchange</th>
            <th>Min Amount Fee</th>
            <th>Payout Fee</th>
            <th>Min Amount Fee</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(feesStore.list.c2b).map((key) => {
            return (
              <tr key={key}>
                <td>{key}</td>
                <td>
                  {
                    feesStore.list.c2b[key].customer_deposit_with_exchange
                      ?.percent_value
                  }
                  %
                </td>
                <td>
                  {
                    feesStore.list.c2b[key].customer_deposit_with_exchange
                      ?.min_amount
                  }{' '}
                  {
                    feesStore.list.c2b[key].customer_deposit
                      .min_amount_coin_name
                  }
                </td>
                <td>
                  {feesStore.list.c2b[key].customer_deposit?.percent_value}%
                </td>
                <td>
                  {feesStore.list.c2b[key].customer_deposit?.min_amount}{' '}
                  {
                    feesStore.list.c2b[key].customer_deposit
                      ?.min_amount_coin_name
                  }
                </td>
                <td>
                  {
                    feesStore.list.c2b[key].customer_withdraw_with_exchange
                      ?.percent_value
                  }
                  %
                </td>
                <td>
                  {
                    feesStore.list.c2b[key].customer_withdraw_with_exchange
                      ?.min_amount
                  }{' '}
                  {
                    feesStore.list.c2b[key].customer_deposit
                      ?.min_amount_coin_name
                  }
                </td>
                <td>
                  {feesStore.list.c2b[key].customer_withdraw?.percent_value}%
                </td>
                <td>
                  {feesStore.list.c2b[key].customer_withdraw?.min_amount}{' '}
                  {
                    feesStore.list.c2b[key].customer_deposit
                      ?.min_amount_coin_name
                  }
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </>
  )
})
export default FeesPage
export { C2b, B2b }
