import type React from 'react'
import './CreateBalanceReport.scss'
import Datepicker from 'src/ui/Datepicker/Datepicker'
import Button from 'src/ui/Button'
import { useStore } from 'src/models/Store'
import useSideBarModal from 'src/hooks/useSideBarModal'
import { Controller, type SubmitHandler, useForm } from 'react-hook-form'

type FormInputs = {
  selectedDates: [Date, Date | null]
}

const CreateBalanceReport: React.FC = () => {
  const { reportsStore } = useStore()
  const { closeModal } = useSideBarModal()
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting }
  } = useForm<FormInputs>({
    defaultValues: { selectedDates: [new Date(), null] }
  })

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    const response = await reportsStore.createReport(
      'balances',
      data.selectedDates[0],
      data.selectedDates[1]
    )

    if (response !== undefined) {
      closeModal()
    }
  }

  return (
    <div className="CreateBalanceReport">
      <h2>Create report</h2>
      <p>Select period</p>
      <Controller
        name="selectedDates"
        control={control}
        rules={{
          validate: {
            endDateNotNull: (date) => date[1] !== null || 'End date is required'
          }
        }}
        render={({ field: { onChange } }) => (
          <Datepicker onDateChange={onChange} />
        )}
      />
      <div className="InputError">{errors.selectedDates?.message}</div>
      <Button
        title="Create report"
        onClick={handleSubmit(onSubmit)}
        isLoading={isSubmitting}
      />
    </div>
  )
}
export default CreateBalanceReport
