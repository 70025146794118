import { type ReactElement, type SVGProps } from 'react'

const LinkIcon = (props: SVGProps<SVGSVGElement>): ReactElement<SVGElement> => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 30"
      fill="none"
      stroke="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.37468 7.875C9.37468 7.875 7.29134 7.875 7.29134 7.875C4.41486 7.875 2.08301 10.3934 2.08301 13.5C2.08301 16.6066 4.41486 19.125 7.29134 19.125C7.29134 19.125 9.37468 19.125 9.37468 19.125"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M8.33301 13.5H16.6663" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M15.6253 19.125C15.6253 19.125 17.7087 19.125 17.7087 19.125C20.5851 19.125 22.917 16.6066 22.917 13.5C22.917 10.3934 20.5851 7.875 17.7087 7.875C17.7087 7.875 15.6253 7.875 15.6253 7.875"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}
export default LinkIcon
