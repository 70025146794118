import { Form } from 'react-bootstrap'
import { type FC } from 'react'
import OtpInput from 'react-otp-input'
import { Controller, type Control } from 'react-hook-form'

interface IOtpInput {
  control: Control<any, any>
  name: string
  label?: string
  /**
   * In some instances animations get laggy when autofocus is on, this disables autofocus
   */
  noAutoFocus?: boolean
}

export const OTPInput: FC<IOtpInput> = ({
  control,
  name,
  label,
  noAutoFocus
}) => {
  return (
    <Form.Group className="mb-4">
      <Form.Label>
        {label ??
          'Please enter the sign-in 2FA code from your authenticator app'}
      </Form.Label>
      <Controller
        name={name}
        control={control}
        defaultValue={''}
        render={({ field }) => {
          return (
            <OtpInput
              onChange={field.onChange}
              numInputs={6}
              containerStyle={'otp-input-container'}
              inputStyle={'otp-input form-control'}
              renderSeparator={<span>-</span>}
              value={field.value}
              renderInput={(props) => <input {...props} />}
              shouldAutoFocus={!(noAutoFocus ?? false)}
            />
          )
        }}
      />
    </Form.Group>
  )
}
