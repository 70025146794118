import { type ReactElement, type SVGProps } from 'react'

const LockIcon = (props: SVGProps<SVGSVGElement>): ReactElement<SVGElement> => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      stroke="white"
      {...props}
    >
      <path
        d="M19.9893 9.6612C19.9893 14.309 16.2064 18.6622 11.0382 19.9358C10.6865 20.0214 10.3029 20.0214 9.95122 19.9358C4.78293 18.6622 1 14.309 1 9.6612V5.48858C1 4.70919 1.66069 3.82526 2.48122 3.53061L8.41673 1.36356C9.74876 0.878814 11.2513 0.878814 12.5833 1.36356L18.5188 3.53061C19.3287 3.82526 20 4.70919 20 5.48858L19.9893 9.6612Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 12.1709C11.8807 12.1709 13 11.0516 13 9.6709C13 8.29019 11.8807 7.1709 10.5 7.1709C9.11925 7.1709 8 8.29019 8 9.6709C8 11.0516 9.11925 12.1709 10.5 12.1709Z"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 12.5C11 12.2239 10.7761 12 10.5 12C10.2239 12 10 12.2239 10 12.5V15.3514C10 15.6276 10.2239 15.8514 10.5 15.8514C10.7761 15.8514 11 15.6276 11 15.3514V12.5Z"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export default LockIcon
