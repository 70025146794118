import { type SVGProps, type ReactElement } from 'react'

const UserProfileIcon = (
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGElement> => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_8_479)">
        <path d="M21.1255 3.4528C18.7008 1.1531 15.5387 -0.0717401 12.2017 0.00325019C8.8646 0.0907388 5.7525 1.46556 3.4528 3.87775C1.1531 6.30243 -0.0717401 9.46452 0.00325019 12.8016C0.0907388 16.1387 1.46556 19.2507 3.87775 21.5504C3.97773 21.6504 4.07772 21.7379 4.19021 21.8254C6.48991 23.8876 9.40203 25 12.4891 25H12.8016C15.7887 24.925 18.6008 23.8127 20.813 21.8254C21.063 21.6129 21.313 21.3755 21.5504 21.1255C23.7877 18.7758 25 15.7262 25 12.4891V12.2017C24.9125 8.8646 23.5377 5.7525 21.1255 3.4528ZM12.7641 23.1252C10.2394 23.1752 7.82723 22.3628 5.88999 20.8005C6.45241 19.2257 7.98971 18.1384 9.68949 18.1384H15.3138C17.0135 18.1384 18.5508 19.2257 19.1133 20.8005C17.3135 22.2629 15.1138 23.0628 12.7641 23.1252ZM20.5506 19.4132C19.5382 17.526 17.5385 16.2636 15.3138 16.2636H9.68949C7.46478 16.2636 5.45254 17.526 4.45267 19.4257C2.84038 17.576 1.9405 15.2513 1.87801 12.7641C1.81551 9.92696 2.85288 7.22731 4.81512 5.17758C6.76487 3.11535 9.40203 1.953 12.2392 1.87801H12.5141C15.2513 1.87801 17.8384 2.91537 19.8257 4.81512C20.8505 5.79 21.6629 6.93985 22.2254 8.18969C22.7753 9.45202 23.0877 10.8268 23.1252 12.2392C23.1877 14.8888 22.2879 17.426 20.5506 19.4132Z" />
        <path d="M15.9384 14.3765H12.1889C9.95172 14.3765 8.12695 12.5518 8.12695 10.3146V7.8149C8.12695 5.57769 9.95172 3.75293 12.1889 3.75293H12.8138C15.0511 3.75293 16.8758 5.57769 16.8758 7.8149V13.4392C16.8758 13.9516 16.4509 14.3765 15.9384 14.3765ZM12.1889 5.62769C10.9891 5.62769 10.0017 6.61506 10.0017 7.8149V10.3146C10.0017 11.5144 10.9891 12.5018 12.1889 12.5018H15.0011V7.8149C15.0011 6.61506 14.0137 5.62769 12.8138 5.62769H12.1889Z" />
      </g>
      <defs>
        <clipPath id="clip0_8_479">
          <rect width="25" height="25" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default UserProfileIcon
