import type React from 'react'
import { observer } from 'mobx-react'
import BaseSideBarComponent from 'src/components/BaseSideBarComponent/BaseSideBarComponent'
import DotsMenuIcon from 'src/assets/images/svg/DotsMenuIcon'
import { useStore } from 'src/models/Store'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import OTPCodeInput from 'src/components/Modals/ApiKeysPageModals/OTPCodeInput/OTPCodeInput'

type FormInputs = {
  tfa_code: string
}

const FiatAddressesDelete: React.FC = observer(() => {
  const { fiatStore, utilityStore } = useStore()
  const { id } = useParams() as { id: string }

  const {
    formState: { isSubmitting },
    handleSubmit,
    control
  } = useForm<FormInputs>()

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    const { tfa_code } = data
    const response = await fiatStore.DeleteFiatBankAccount({
      id,
      tfa_code
    })

    if (response !== undefined) {
      utilityStore.closeSideBarModal()
    }
  }

  return (
    <BaseSideBarComponent
      checkTFAEnabled
      headerTitle="Delete Address"
      headerIcon={<DotsMenuIcon />}
    >
      <OTPCodeInput
        key={1}
        control={control}
        onSubmit={handleSubmit(onSubmit)}
        isSubmitting={isSubmitting}
        noAutoFocus
      />
    </BaseSideBarComponent>
  )
})
export default FiatAddressesDelete
