import type Store from './Store'
import { autorun, makeAutoObservable, runInAction, toJS, when } from 'mobx'
import FiltersStore from './FiltersStore'
import PaginationStore from './PaginationStore'
import { type TBalanceItem } from './BalancesStore'
import { type BasePaginationRequest } from './FetchModel'

export type TTransactionsGetParams = {
  page: number
  page_size?: number
  foreign_id?: string
  type?: string
  status?: string
  network?: string
  coin?: string
}

export type TAddressesListItem = {
  id: number
  address: string
  network: string
  foreign_id: string
  is_locked: boolean
}

export type TAddAddressesParams = {
  tag: string
  slug: string
  network_id: number
  name: string
  tfa_code: string
}

export type TDeleteAddressParams = {
  id: number
  tfa_code: number | string
}

export const addressConfigPageFilters = [
  'slug',
  'network',
  'foreign_id',
  'address_type',
  'merchant',
  'date_range',
  'page',
  'page_size',
  'network_id'
] as const

// const qwe = {
//   'slug: string,
//   'network',
//   'foreign_id',
//   'address_type',
//   'merchant',
//   'date_range',
//   'page',
//   'page_size',
//   'network_id'
// }

class AddressesStore {
  constructor(private readonly rootStore: Store) {
    this.rootStore = rootStore
    makeAutoObservable<AddressesStore, 'rootStore'>(this, { rootStore: false })

    this.filters = new FiltersStore(rootStore, [...addressConfigPageFilters])
    this.pagination = new PaginationStore()
    when(
      () =>
        this.rootStore.authStore.isAuth &&
        !Number.isNaN(this.rootStore.merchantStore.currentMerchant.id),
      () => {
        autorun(() => {
          if (!Number.isNaN(this.rootStore.merchantStore.currentMerchant.id)) {
            void this.getAddresses({
              page_size: 10,
              page: this.pagination.currentPage,
              ...this.filters.searchString
            })
          }
        })
      }
    )
  }

  filters: FiltersStore<typeof addressConfigPageFilters>
  pagination: PaginationStore
  list: TAddressesListItem[] = []
  total: number = 0
  isLoading: boolean = false
  isFetched: boolean = false
  loadingError: boolean = false

  selectedCoinForWithdraw: TBalanceItem | undefined

  setSelectedCoinForWithdraw(coin: TBalanceItem): void {
    this.selectedCoinForWithdraw = coin
  }

  async getAddresses(params: TTransactionsGetParams): Promise<void> {
    const search = { ...params, ...toJS(this.filters.searchString) }
    this.isLoading = true
    const response = await this.rootStore.api.get<
      { items: TAddressesListItem[] } & BasePaginationRequest
    >('/jsapi/addresses', {
      ...search
    })

    if (response !== undefined) {
      runInAction(() => {
        this.list = response.items
        this.pagination.init(response.page_size, response.total)
        this.isFetched = true
        this.isLoading = false
        this.loadingError = false
      })
    }
  }
}

export default AddressesStore
