import type Store from './Store'
import { makeAutoObservable, runInAction } from 'mobx'
import { EAlertTypes } from './AlertsStore'

class TwoFactorAuthStore {
  otp_qr_url: string = ''
  otp_secret: string = ''
  isLoading: boolean = false
  hasErrors: boolean = false

  constructor(private readonly rootStore: Store) {
    makeAutoObservable<TwoFactorAuthStore, 'rootStore'>(this, {
      rootStore: false
    })
    this.rootStore = rootStore
  }

  async getOneTimePasswordQRCode(): Promise<void> {
    this.isLoading = true
    this.hasErrors = false
    const response = await this.rootStore.api.get<{
      otp_qr_url: string
      otp_secret: string
      is_2fa_enabled: boolean
    }>('/jsapi/user/otp_status')

    if (response !== undefined) {
      runInAction(() => {
        this.isLoading = false
        this.otp_qr_url = response.otp_qr_url
        this.otp_secret = response.otp_secret
      })
    }
  }

  async setOtpStatus(params: {
    otp_code: string
    is_enabled: boolean
  }): Promise<
    | {
        is_enabled: boolean
      }
    | undefined
  > {
    const response = await this.rootStore.api.post<{
      is_enabled: boolean
    }>('/jsapi/user/otp_status', {
      ...params
    })
    if (response !== undefined) {
      runInAction(
        () => (this.rootStore.userStore.isTFAEnabled = response.is_enabled)
      )
      this.rootStore.alertsStore.addAlert({
        title: 'Success',
        content: response.is_enabled
          ? '2FA Authentication successfully set!'
          : '2FA Authentication successfully disabled!',
        type: response.is_enabled ? EAlertTypes.SUCCESS : EAlertTypes.WARNING,
        timeout: 10000,
        id: 'success-2fa'
      })
      return response
    }
  }
}

export default TwoFactorAuthStore
