import type React from 'react'
import Input from 'src/ui/Input/Input'
import Button, { EButtonType } from 'src/ui/Button'
import { useNavigate } from 'react-router-dom'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { useStore } from 'src/models/Store'

type PasswordResetFormInputs = {
  email: string
}

const PasswordResetPage: React.FC = () => {
  const navigate = useNavigate()
  const { userStore } = useStore()

  const {
    register,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm<PasswordResetFormInputs>()

  const onSubmit: SubmitHandler<PasswordResetFormInputs> = async ({
    email
  }) => {
    const response = await userStore.resetPasswordUsingEmail(email)
    console.log(response)
    if (response !== undefined) {
      navigate('success')
    }
  }
  return (
    <div className="PasswordResetPage">
      <form>
        <h3>Forgot Password</h3>
        <p>Reset your password by entering your email</p>
        <Input
          autoFocus
          label="Email"
          {...register('email', { required: true })}
        />
        <Button
          title="Reset"
          onClick={handleSubmit(onSubmit)}
          disabled={isSubmitting}
        />
        <Button
          title="Back to login"
          type="button"
          buttonType={EButtonType.LINK}
          onClick={() => {
            navigate('/login')
          }}
        />
      </form>
    </div>
  )
}
export default PasswordResetPage
