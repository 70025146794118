import type React from 'react'
import './Datepicker.scss'
import { useState } from 'react'
import DatePicker from 'react-datepicker'
import { Dropdown } from 'react-bootstrap'

interface DatepickerProps {
  /**
   * use this callback to get back the date set by the user
   */
  onDateChange: (dates: [Date, Date | null]) => void
}

/**
 * Datepicker component that gives back date set in it
 */
const Datepicker: React.FC<DatepickerProps> = ({ onDateChange }) => {
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [selectedDate, setSelectedDate] = useState<string>('Custom')

  const today = new Date()

  const onChange = (dates: [Date, Date | null]): void => {
    const [start, end] = dates
    setSelectedDate('Custom')
    setStartDate(start)
    setEndDate(end)

    onDateChange(dates)
  }

  const handleSelectChange = (
    selectTitle: string,
    daysBeforeToday?: number,
    monthsBeforeToday?: number,
    yearsBeforeToday?: number
  ): void => {
    const newStartDate = new Date(
      yearsBeforeToday !== undefined
        ? today.getFullYear() - yearsBeforeToday
        : today.getFullYear(),
      monthsBeforeToday !== undefined
        ? today.getMonth() - monthsBeforeToday
        : today.getMonth(),
      daysBeforeToday !== undefined
        ? today.getDate() - daysBeforeToday + 1
        : today.getDate() + 1
    )
    setStartDate(newStartDate)
    setEndDate(today)
    setSelectedDate(selectTitle)
    onDateChange([newStartDate, today])
  }

  return (
    <div className="Datepicker">
      <div className="Datepicker-Left">
        <DatePicker
          selected={startDate}
          onChange={onChange}
          startDate={startDate}
          endDate={endDate}
          maxDate={today}
          selectsRange
          inline
          allowSameDay
        />
      </div>
      <div className="Datepicker-Right">
        Period
        <Dropdown>
          <Dropdown.Toggle
            variant="dark"
            size={'sm'}
            className="Datepicker-Right-Dropdown"
          >
            {selectedDate}
          </Dropdown.Toggle>
          <Dropdown.Menu variant={'dark'}>
            <Dropdown.Item
              onClick={() => {
                handleSelectChange('Today')
              }}
            >
              Today
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                handleSelectChange('Last 7 days', 7)
              }}
            >
              Last 7 days
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                handleSelectChange('Last 4 weeks', 28)
              }}
            >
              Last 4 weeks
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                handleSelectChange('Last 3 months', 0, 3)
              }}
            >
              Last 3 months
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                handleSelectChange('Last 12 months', 0, 0, 1)
              }}
            >
              Last 12 months
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        From
        <div className="Datepicker-Right-Date">
          {startDate.toJSON().slice(0, 10)}
        </div>
        To
        <div className="Datepicker-Right-Date">
          {endDate !== null ? endDate?.toJSON().slice(0, 10) : '-'}
        </div>
      </div>
    </div>
  )
}
export default Datepicker
