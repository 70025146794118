import type React from 'react'
import './OTPCodeInput.scss'
import { type Control } from 'react-hook-form'
import { OTPInput } from 'src/ui/Input/OtpInput'
import Button, { EButtonType } from 'src/ui/Button'
import { type BaseSyntheticEvent } from 'react'
import { useStore } from 'src/models/Store'

interface OTPCodeInputProps {
  control: Control<any, any>
  label?: string
  onSubmit: (
    e?: BaseSyntheticEvent<object, any, any> | undefined
  ) => Promise<void>
  isSubmitting: boolean
  noAutoFocus?: boolean
  /**
   * Provide custom logic when Cancel button is clicked
   */
  onCancel?: () => void
}

/**
 * OTP input with submit and cancel buttons
 */
const OTPCodeInput: React.FC<OTPCodeInputProps> = ({
  control,
  label,
  onSubmit,
  isSubmitting,
  noAutoFocus,
  onCancel
}) => {
  const { utilityStore } = useStore()

  return (
    <div className="OTPCodeInput">
      <h2>2FA authentication</h2>
      <form>
        <OTPInput
          control={control}
          name="tfa_code"
          label={label}
          noAutoFocus={noAutoFocus}
        />
        <div className="OTPCodeInput-ButtonGroup">
          <Button
            title="Submit"
            type="submit"
            onClick={onSubmit}
            isLoading={isSubmitting}
          />
          <Button
            title="Cancel"
            type="button"
            buttonType={EButtonType.SECONDARY}
            disabled={isSubmitting}
            onClick={() => {
              if (onCancel !== undefined) {
                onCancel()
                return
              }
              utilityStore.closeSideBarModal()
            }}
          />
        </div>
      </form>
    </div>
  )
}
export default OTPCodeInput
