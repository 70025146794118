import { type FC } from 'react'
import { Col, Row, Table } from 'react-bootstrap'
import { Outlet, useNavigate } from 'react-router-dom'
import { useStore } from '../../models/Store'
import { type TTransactionListItem } from '../../models/TransactionStore'
import { observer } from 'mobx-react'
import PaginationComponent from '../../ui/Pagination/Pagination'
import TransactionFilters from './TransactionFilters'
import TransactionsSubFilter from './TransactionsSubFilters'

const TransactionsList = observer(() => {
  const store = useStore()
  return (
    <>
      <div
        style={{
          alignItems: 'center',
          marginBlock: 20
        }}
      >
        <Col xs={'auto'}>
          <TransactionFilters />
        </Col>
      </div>
      <TransactionsSubFilter />
      <Col>
        <RenderTable
          list={store.transactionStore.list}
          loadingError={store.transactionStore.loadingError}
        />
      </Col>
      <Row>
        <PaginationComponent pagination={store.transactionStore.pagination} />
      </Row>
      <Outlet />
    </>
  )
})

interface ITransactionsListTable {
  list: TTransactionListItem[]
  loadingError: boolean
}

const RenderTable: FC<ITransactionsListTable> = observer(
  ({ list, loadingError }) => {
    if (loadingError) return <p>Transaction loading error</p>
    if (list.length === 0) return <p>Empty list</p>
    return (
      <Table responsive={true}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Created</th>
            <th>Amount</th>
            <th>Network fee</th>
            <th>Type</th>
            <th style={{ minWidth: 110 }}>Status</th>
            <th>Real amount</th>
            <th>Amount with commission</th>
            <th>Transaction ID</th>
            <th>Network</th>
            <th style={{ minWidth: 150 }}>Coin</th>
            <th>Foreign ID</th>
          </tr>
        </thead>
        <tbody>
          {list.map((item: TTransactionListItem) => (
            <RenderRow item={item} key={item.id} />
          ))}
        </tbody>
      </Table>
    )
  }
)

interface TableRowProps {
  item: TTransactionListItem
}

const RenderRow: FC<TableRowProps> = observer(({ item }) => {
  const navigate = useNavigate()
  const store = useStore()
  const imageName =
    item.coin.toLowerCase().search('usdt') !== -1
      ? 'usdt'
      : item.coin.toLowerCase()
  return (
    <tr
      className={'t-row hover'}
      key={item.id}
      onClick={() => {
        navigate(`info/${item.id}`, { state: { id: item.id } })
      }}
    >
      <td>
        <span className={'table-badge bg-primary700'}>{item.id}</span>
      </td>
      <td>{item.created.replace(' ', '\n')}</td>
      <td>{item.amount}</td>
      <td>{item.network_fee ?? 'N/A'}</td>
      <td>{item.type}</td>
      <td width={'140px'}>
        <span className={`status status-${item.status}`} />
        {item.status}
      </td>
      <td>{item.real_amount ?? 'N/A'}</td>
      <td>{item.amount_with_commission}</td>
      <td>
        <a
          target="_blank"
          href={item.txid_url}
          className={'link text-truncate'}
          style={{ display: 'block', maxWidth: 150 }}
          rel="noreferrer"
        >
          {item.txid}
        </a>
      </td>
      <td>{item.network}</td>
      <td width={'140px'}>
        <img
          src={process.env.PUBLIC_URL + `/assets/images/ps/${imageName}.svg`}
          alt={imageName}
        />{' '}
        {item.coin}
      </td>
      <td>
        <span className={'table-badge bg-primary700'}>
          {item.foreign_id ?? 'N/A'}
        </span>
      </td>
    </tr>
  )
})

export default TransactionsList
