import { observer } from 'mobx-react'
import { useStore } from '../../../models/Store'
import WithdrawAccount from '../../../ui/WithdrawAccount/WithdrawAccount'
import Button from '../../../ui/Button'
import { useNavigate, useParams } from 'react-router-dom'
import DepositLimit from '../../../ui/DepositLimit/DepositLimit'

const SavedWithdrawAccounts = observer(() => {
  const { fiatStore } = useStore()
  const navigate = useNavigate()
  const params = useParams()
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
      <DepositLimit
        min={fiatStore.withdrawLimits.min}
        max={fiatStore.withdrawLimits.max}
      />
      <Button
        title={'Add new account'}
        wide
        onClick={() => {
          navigate(
            `/balances/fiat/new-withdraw-fiat-acc/${params.id as string}`
          )
        }}
      />
      {fiatStore.savedWithdrawAccounts.map((acc) => (
        <WithdrawAccount
          key={acc.id}
          name={acc.account_name}
          iban={acc.iban}
          onClick={() => {
            navigate(
              `/balances/fiat/fiat-payout/${params.id as string}/${acc.id}`
            )
          }}
        />
      ))}
    </div>
  )
})

export default SavedWithdrawAccounts
