import { makeAutoObservable, runInAction } from 'mobx'
import type Store from './Store'
export type RedirectPayment = {
  id: string
  merchant: {
    id: number
    name: string
  }
  status: 'Created' | 'Pending' | 'Success'
  coin_name: string
  currency: string
  network_name: string
  payment_attributes: {
    address: string
    memo?: string
  }
  income?: {
    amount: number
    expected_confirmations: number
    confirmations: number
  }
  return_url?: string
  date_created: Date
}

class RedirectPaymentsStore {
  constructor(private readonly rootStore: Store) {
    this.rootStore = rootStore
    makeAutoObservable<RedirectPaymentsStore, 'rootStore'>(this, {
      rootStore: false
    })
  }

  payment: RedirectPayment | null = null
  paymentStatus: RedirectPayment['status'] | null = null

  public async getPaymentDeposit(
    id: RedirectPayment['id']
  ): Promise<typeof response> {
    const response = await this.rootStore.api.unprotectedGet<RedirectPayment>(
      '/public-api/get-payment',
      { payment_id: id }
    )
    if (response !== undefined) {
      runInAction(() => {
        this.payment = response
        this.paymentStatus = response.status
      })
      return response
    }
  }
}

export default RedirectPaymentsStore
