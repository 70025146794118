/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import type Store from './Store'
import { autorun, makeAutoObservable, toJS, when } from 'mobx'
import FiltersStore from './FiltersStore'
import { type TCoinItem } from './InitialDataStore'

export type TBalanceItem = {
  balance: string
  coin: string
  is_virtual: boolean
  currency: string
  id: number
  address: string
  network: string
  is_available_exchange: boolean
  is_available_topup: boolean
  is_available_withdraw: boolean
  network_id: number
  memo?: string
}
export type TBalanceGetParams = {
  is_virtual?: boolean
}
export type TBalanceWithdrawParams = {
  network_id: number
  coin_id: number
  from_balance_id: number
  amount: number
  destination: string
  tfa_code: string
}

class BalancesStore {
  constructor(private readonly rootStore: Store) {
    this.rootStore = rootStore
    makeAutoObservable<BalancesStore, 'rootStore'>(this, { rootStore: false })
    this.filters = new FiltersStore(rootStore, ['is_virtual'])
    when(
      () =>
        this.rootStore.authStore.isAuth &&
        !!this.rootStore.merchantStore.currentMerchant.id,
      () => {
        autorun(() => {
          if (this.rootStore.merchantStore.currentMerchant) {
            this.getBalances({ ...toJS(this.filters.searchString) })
          }
        })
      }
    )
  }

  public items: TBalanceItem[] = []
  public filters: FiltersStore<['is_virtual']>

  async getBalances(params: TBalanceGetParams): Promise<void> {
    const search = { ...toJS(params) }
    const response = await this.rootStore.api.get<TBalanceItem[]>(
      '/jsapi/balances',
      {
        ...search
      }
    )

    if (response !== undefined) {
      this.setBalancesItems(response)
    }
  }

  setBalancesItems(items: TBalanceItem[]): void {
    this.items = items
  }

  async withdraw(params: {
    coin_id: number
    amount: number
    destination: number
  }): Promise<
    | {
        need_confirm: boolean
        operation_id: number
        transaction_id: number
      }
    | undefined
  > {
    const payload = {
      amount: +params.amount,
      coin_id: params.coin_id,
      destination: params.destination
    }
    return await this.rootStore.api.post<{
      need_confirm: boolean
      operation_id: number
      transaction_id: number
    }>('/jsapi/settlement', { ...payload })
  }

  findIdByName(
    search: 'coins' | 'networks',
    name: string
  ): TCoinItem['id'] | undefined {
    const s = this.rootStore.initialStore[search].items
    return s.find((v) => {
      return v.name === name
    })?.id
  }

  // TODO: Probably need to change findIdByName to return the whole thing later on
  findIdByNameAndReturn(
    search: 'coins' | 'networks',
    name: string
  ): TCoinItem | undefined {
    const s = this.rootStore.initialStore[search].items
    return s.find((v) => {
      return v.name === name
    })
  }

  /**
   * Tries to find a balance item with given key value pair
   *
   * Example: findBalanceItem('coin', 'ETH')
   */
  findBalanceItem(
    itemKeyName: keyof TBalanceItem,
    value: string | number | boolean
  ): TBalanceItem | undefined {
    return this.items.find((item) => item[itemKeyName] === value)
  }
}

export default BalancesStore
