import type React from 'react'
import { useEffect } from 'react'
import './BalanceReports.scss'
import BaseDataTable from 'src/components/BaseDataTable/BaseDataTable'
import useSideBarModal from 'src/hooks/useSideBarModal'
import Payin from 'src/assets/images/svg/Payin.svg'

import Button, { EButtonSize } from 'src/ui/Button'
import CreateBalanceReport from './CreateBalanceReport'
import ArrowIcon from 'src/assets/images/svg/ArrowIcon'
import { colors } from 'src/assets/scss/themes'
import { observer } from 'mobx-react'
import { useStore } from 'src/models/Store'
import type { TableColumn } from 'react-data-table-component'
import type { IReport } from 'src/models/ReportsStore'

const columns: Array<TableColumn<IReport>> = [
  { name: 'Created', selector: (row) => row.date_created, id: 1 },
  {
    name: 'Action',
    cell: (row) => downloadReportButton(row.id),
    button: true,
    minWidth: '140px',
    id: 3
  }
]

const downloadReportButton = (reportId: number): JSX.Element => {
  const { reportsStore } = useStore()

  return (
    <Button
      onClick={() => {
        reportsStore.getReportDownloadable(reportId)
      }}
      icon={<Payin stroke={colors.bg} />}
      title="Download"
      size={EButtonSize.SM}
    />
  )
}

const BalanceReports: React.FC = observer(() => {
  const { openModal } = useSideBarModal()
  const { reportsStore, merchantStore } = useStore()
  const { reports } = reportsStore

  useEffect(() => {
    reportsStore.filters.setSearchFields([{ field: 'type', value: 'balances' }])
  }, [])

  return (
    <div className="BalanceReports">
      <div className="BalanceReports-Actions">
        <Button
          title="Create report"
          onClick={() => {
            openModal(
              <CreateBalanceReport />,
              <ArrowIcon stroke={colors.main} />,
              'Create balance report'
            )
          }}
        />
      </div>
      <BaseDataTable
        columns={columns}
        data={reports}
        paginationLogic={reportsStore.pagination}
      />
    </div>
  )
})
export default BalanceReports
