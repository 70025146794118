import { makeAutoObservable } from 'mobx'

class PaginationStore {
  currentPage = 1
  itemsPerPage = 10
  total = 0
  isInitiated = false

  constructor() {
    makeAutoObservable<PaginationStore>(this)
  }

  init(pageSize: number, total: number): void {
    this.itemsPerPage = pageSize
    this.total = total
    this.isInitiated = true
  }

  get pagesLength(): number {
    return Math.ceil(this.total / this.itemsPerPage)
  }

  get activeBack(): boolean {
    return this.currentPage > 0
  }

  get activeNext(): boolean {
    return this.currentPage < this.total
  }

  setPage(page: number): void {
    this.currentPage = page
  }

  next(): void {
    this.setPage(this.currentPage + 1)
  }

  reset(): void {
    this.currentPage = 1
    this.itemsPerPage = 10
    this.total = 0
  }

  back(): void {
    this.setPage(this.currentPage - 1)
  }
}

export default PaginationStore
