import './BaseDataTable.scss'
import DataTable, { type TableProps } from 'react-data-table-component'
import { customStyles } from './BaseDataTableCustomStyles'
import PaginationComponent from 'src/ui/Pagination/Pagination'
import type PaginationStore from 'src/models/PaginationStore'
import { observer } from 'mobx-react'

interface IBaseDataTableProps {
  paginationLogic: PaginationStore
}

/**
 * Use this as a base for react-data-table-component for consistency
 *
 * Has to be implemented with pagination since all data from backend comes with built in pagination
 *
 * For reference check out TransactionStore and PaginationStore classes
 */
const BaseDataTable = observer(
  <T,>(props: TableProps<T> & IBaseDataTableProps): JSX.Element => {
    const { paginationLogic } = props

    return (
      <DataTable
        customStyles={customStyles}
        paginationComponent={() => (
          <PaginationComponent pagination={paginationLogic} />
        )}
        pagination
        noDataComponent={<p className={'color-white'}>Nothing found...</p>}
        paginationServer
        responsive
        {...props}
      />
    )
  }
)
export default BaseDataTable
