import { observer } from 'mobx-react'
import { useStore } from '../../models/Store'
import { type TAlert } from '../../models/AlertsStore'
import { type FC } from 'react'
import './Alert.scss'

type TAlertProps = {
  alert: TAlert
}

const Alert: FC<TAlertProps> = observer(({ alert }) => {
  const store = useStore()
  return (
    <div className={`alert ${alert.type}`}>
      <p style={{ fontWeight: 'bold' }}>{alert.title}</p>
      <p>{alert.content}</p>
      <div
        className={'close'}
        onClick={() => {
          store.alertsStore.removeAlert(alert.id)
        }}
      >
        x
      </div>
    </div>
  )
})

export default Alert
