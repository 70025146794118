import type React from 'react'
import '../WithdrawPage.scss'
import BaseSideBarComponent from 'src/components/BaseSideBarComponent/BaseSideBarComponent'
import WithdrawIcon from 'src/assets/images/svg/WithdrawIcon'
import { colors } from 'src/assets/scss/themes'
import Button, { EButtonType } from 'src/ui/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import ModalOTPInput from 'src/components/ModalOTPInput/ModalOTPInput'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import { useStore } from 'src/models/Store'
import Input from 'src/ui/Input/Input'
import { type UseParamsWithdrawManage } from '../WithdrawPage'
import NoTFASideBarComponent from 'src/components/EnableTFASidebarComponent/EnableTFASidebarComponent'

type WithdrawFormInputs = {
  name: string
  tfa_code: string
}

const WithdrawAddressesEdit: React.FC = observer(() => {
  const [formStep, setFormStep] = useState<'edit' | '2fa'>('edit')
  const navigate = useNavigate()
  const { withdrawAddressesStore, userStore } = useStore()
  const { addressId, networkId } =
    useParams<UseParamsWithdrawManage>() as UseParamsWithdrawManage

  const addressToEdit = withdrawAddressesStore.withdrawAddresses.find(
    (address) => address.id === parseInt(addressId)
  )

  const {
    control,
    handleSubmit,
    register,
    formState: { isSubmitting },
    trigger
  } = useForm<WithdrawFormInputs>()

  // Get addresses on first render in case of a direct link
  useEffect(() => {
    if (withdrawAddressesStore.withdrawAddresses.length <= 0) {
      void withdrawAddressesStore.getWithdrawAddresses({
        address: parseInt(addressId)
      })
    }
  }, [])

  const onSubmit: SubmitHandler<WithdrawFormInputs> = async ({
    tfa_code,
    name
  }) => {
    if (addressId !== undefined) {
      const response = await withdrawAddressesStore.editWithdrawAddress({
        addressId,
        tfa_code,
        name
      })

      if (response !== undefined) {
        navigate('../..')
      }
    }
  }

  const renderFormStep = (): JSX.Element => {
    if (formStep === 'edit') {
      return (
        <div className="WithdrawPage">
          <h3>Edit address name</h3>
          <div className="WithdrawPage-AddressManage">
            <div>
              {addressToEdit?.name}
              <div className="WithdrawPage-AddressManage__slug">
                {addressToEdit?.slug}
              </div>
            </div>
          </div>
          <Input
            label="Address name"
            {...register('name', { required: true })}
          />
          <div className="WithdrawPage-ButtonGroup">
            <Button
              title="Save"
              onClick={async () => {
                const validateBefore2fa = await trigger('name', {
                  shouldFocus: true
                })
                if (validateBefore2fa) {
                  setFormStep('2fa')
                }
              }}
            />
            <Button
              title="Cancel"
              buttonType={EButtonType.SECONDARY}
              onClick={() => {
                navigate(-1)
              }}
            />
          </div>
        </div>
      )
    }
    if (formStep === '2fa') {
      return (
        <ModalOTPInput
          control={control}
          isSubmitting={isSubmitting}
          onSubmit={handleSubmit(onSubmit)}
        />
      )
    }
    return <div>Something went wrong, please contact support</div>
  }
  return (
    <BaseSideBarComponent
      headerIcon={<WithdrawIcon stroke={colors.main} />}
      headerTitle={'Withdraw'}
    >
      {userStore.user.tfa_enabled ? (
        renderFormStep()
      ) : (
        <NoTFASideBarComponent />
      )}
    </BaseSideBarComponent>
  )
})
export default WithdrawAddressesEdit
