import type React from 'react'
import './WithdrawFiatPage.scss'
import BaseSideBarComponent from 'src/components/BaseSideBarComponent/BaseSideBarComponent'
import { colors } from 'src/assets/scss/themes'
import Payin from '../../assets/images/svg/Payin.svg'
import { useParams } from 'react-router-dom'
import FiatWithdrawForm from '../../components/FiatWithdrawForm/FiatWithdrawForm'
import { useStore } from '../../models/Store'
import { observer } from 'mobx-react'
import { useEffect } from 'react'

const WithdrawFiatPage: React.FC = observer(() => {
  const { fiatStore } = useStore()

  const params = useParams()

  useEffect(() => {
    fiatStore.FetchWithdraw()
  }, [])

  return (
    <BaseSideBarComponent
      headerIcon={<Payin fill={colors.main} stroke={colors.main} />}
      headerTitle={`Withdraw to your ${params.id as string} account`}
    >
      <div className="WithdrawFiatPage">
        <FiatWithdrawForm />
      </div>
    </BaseSideBarComponent>
  )
})
export default WithdrawFiatPage
