import type React from 'react'
import '../WithdrawPage.scss'
import BaseSideBarComponent from 'src/components/BaseSideBarComponent/BaseSideBarComponent'
import WithdrawIcon from 'src/assets/images/svg/WithdrawIcon'
import { colors } from 'src/assets/scss/themes'
import { useStore } from 'src/models/Store'
import { observer } from 'mobx-react'
import Button, { EButtonType } from 'src/ui/Button'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import TrashcanIcon from 'src/assets/images/svg/TrashcanIcon'
import PenEditIcon from 'src/assets/images/svg/PenEditIcon'
import { useEffect } from 'react'
import NoTFASideBarComponent from 'src/components/EnableTFASidebarComponent/EnableTFASidebarComponent'

const WithdrawAddressesManage: React.FC = observer(() => {
  const { withdrawAddressesStore, userStore } = useStore()
  const navigate = useNavigate()
  const { networkId } = useParams()
  const location = useLocation()
  console.log(location)

  // Get addresses on first render in case of a direct link
  useEffect(() => {
    if (withdrawAddressesStore.withdrawAddresses.length <= 0) {
      void withdrawAddressesStore.getWithdrawAddresses({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        network_id: parseInt(networkId!)
      })
    }
  }, [])

  if (!userStore.user.tfa_enabled) return <NoTFASideBarComponent />

  return (
    <BaseSideBarComponent
      headerIcon={<WithdrawIcon stroke={colors.main} />}
      headerTitle={'Withdraw'}
    >
      <div className="WithdrawPage">
        <h3>Edit or remove your wallets</h3>
        {withdrawAddressesStore.withdrawAddresses.map((address) => (
          <div className="WithdrawPage-AddressManage" key={address.id}>
            <div style={{ overflow: 'hidden' }}>
              {address.name}
              <div className="WithdrawPage-AddressManage__slug">
                {address.slug}
              </div>
            </div>
            <div className="WithdrawPage-AddressManage__manageButtons">
              <TrashcanIcon
                onClick={() => {
                  navigate(`delete/address/${address.id}`)
                }}
              />
              <PenEditIcon
                onClick={() => {
                  navigate(`edit/address/${address.id}`)
                }}
              />
            </div>
          </div>
        ))}
        <Button
          title="Cancel"
          buttonType={EButtonType.SECONDARY}
          onClick={() => {
            navigate(-1)
          }}
        />
      </div>
    </BaseSideBarComponent>
  )
})
export default WithdrawAddressesManage
