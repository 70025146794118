import MenuButton from 'src/ui/Menu/MenuButton'
import './ReportsPage.scss'
import { Outlet } from 'react-router-dom'

const ReportsPage: React.FC = () => {
  return (
    <>
      <div className="ReportsPage">
        <MenuButton end link="/reports" title="Transaction reports" />
        <MenuButton link="balance-reports" title="Balance reports" />
      </div>
      <Outlet />
    </>
  )
}
export default ReportsPage
