import { type TMerchant } from './MerchantStore'
import type Store from './Store'
import { makeAutoObservable, reaction, runInAction } from 'mobx'

export type TCoinItem = {
  id: number
  name: string
}

export type TCoins = {
  items: TCoinItem[]
}

type TInitialData = {
  user: {
    id: number
    email: string
    lang: string
    tfa_enabled: boolean
    projects: string[]
  }
  LANG: string
  LANGS: string[]
  RATES_WIDGET: Record<
    string,
    Record<
      string,
      {
        minor_unit: string
        title: string
        rate: number
      }
    >
  >
  transactions_types: {
    income: 'Income'
    outcome: 'Outcome'
    top_up: 'Top up'
    settlement: 'Settlement'
    collect: 'Collect'
    internal_income: 'Internal income'
    admin_income: 'Admin income'
  }
  merchant: TMerchant
  merchants: { items: TMerchant[] }
  networks: { items: TCoinItem[] }
  coins: { items: TCoinItem[] }
}

class InitialDataStore {
  public isFetched: boolean = false
  public hasErrors: boolean = false
  public isLoading: boolean = false
  public LANG: string = 'en'
  public LANGS: string[] = []

  public RATES_WIDGET: Record<
    string,
    Record<
      string,
      {
        minor_unit: string
        title: string
        rate: number
      }
    >
  > = {}

  public allCoins: TCoinItem[] = [] as TCoinItem[]
  public coins: TCoins = { items: [] }
  public networks: TCoins = { items: [] }
  public transactions_types: any = {}

  constructor(private readonly rootStore: Store) {
    this.rootStore = rootStore
    makeAutoObservable<InitialDataStore, 'rootStore'>(this, {
      rootStore: false
    })
    reaction(
      () => rootStore.authStore.isAuth,
      () => {
        void this.getInitData()
        void this.getAllCoins()
      }
    )
  }

  public async getAllCoins(): Promise<void> {
    this.isLoading = true
    const response = await this.rootStore.api.get<TCoinItem[]>('/jsapi/coins')
    if (response !== undefined) {
      this.allCoins = response
    }
  }

  public async getInitData(): Promise<void> {
    this.isLoading = true
    const response = await this.rootStore.api.get<TInitialData>(
      'jsapi/init_data'
    )

    if (response !== undefined) {
      runInAction(() => {
        this.LANG = response.LANG
        this.LANGS = response.LANGS
        this.RATES_WIDGET = response.RATES_WIDGET
        this.coins = response.coins
        console.log(response.coins, 'response.coins')
        if (response.user !== null) {
          this.rootStore.merchantStore.setMerchants(response.merchants.items)
          void this.rootStore.merchantStore.setCurrentMerchant(
            response.merchant
          )
          this.rootStore.userStore.setUser(response.user)
        }
        this.networks = response.networks
        this.transactions_types = response.transactions_types

        this.isFetched = true
        this.hasErrors = false
        this.isLoading = false
      })
    } else {
      runInAction(() => {
        this.isFetched = true
        this.hasErrors = true
        this.isLoading = false
      })
      await this.rootStore.userStore.logout()
    }
  }
}

export default InitialDataStore
