import React from 'react'
import './DotsMenuDropdown.scss'
import { Dropdown } from 'react-bootstrap'
import DotsMenuIcon from 'src/assets/images/svg/DotsMenuIcon'

interface DotsMenuDropdownProps {
  children: JSX.Element | JSX.Element[]
}
const dotsMenuDropdown = React.forwardRef<
  HTMLDivElement,
  { onClick: React.MouseEventHandler<SVGSVGElement> }
>(({ onClick }, ref) => (
  <div ref={ref}>
    <DotsMenuIcon
      className="TeamPage-Menu"
      onClick={(e) => {
        onClick(e)
      }}
    />
  </div>
))

/**
 * Displays a button with three dots, on clicking the button a dropdown will appear
 *
 * Therefore you have to use bootstrap's Dropdown.Item component and place dropdown items there as children
 *
 * Example:
 * <Dropdown.Item onClick> -- additional content -- </Dropdown.Item>
 */
const DotsMenuDropdown: React.FC<DotsMenuDropdownProps> = ({ children }) => {
  return (
    <div className="DotsMenuDropdown">
      <Dropdown>
        <Dropdown.Toggle
          as={dotsMenuDropdown}
          id="dropdown-custom-components"
          className={'ctr-btn filter'}
          variant="dark"
        />
        <Dropdown.Menu variant={'dark'}>{children}</Dropdown.Menu>
      </Dropdown>
    </div>
  )
}
export default DotsMenuDropdown
dotsMenuDropdown.displayName = 'DotsMenuDropdownButton'
