import type React from 'react'
// import './PasswordResetSuccess.scss'
import Button from 'src/ui/Button'
import { useNavigate } from 'react-router-dom'

const PasswordResetSuccess: React.FC = () => {
  const navigate = useNavigate()
  return (
    <div className="PasswordResetSuccess">
      <h3>Your password has been successfully reset</h3>
      <p>An email with instructions have been sent to your email.</p>
      <Button
        title="Login"
        onClick={() => {
          navigate('/login')
        }}
      />
    </div>
  )
}
export default PasswordResetSuccess
