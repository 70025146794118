import { type ReactComponentElement, type ComponentPropsWithRef } from 'react'
import Preloader from '../Preloader'
import { colors } from 'src/assets/scss/themes'

export enum EButtonType {
  'PRIMARY' = 'primary',
  'SECONDARY' = 'secondary',
  'FILTER' = 'filter',
  'LINK' = 'link'
}

export enum EButtonSize {
  'XL' = 'btn-xl',
  'SM' = 'btn-sm'
}

interface ButtonProps extends ComponentPropsWithRef<'button'> {
  title: string
  buttonType?: EButtonType
  size?: EButtonSize
  icon?: ReactComponentElement<any>
  classes?: string
  inactive?: boolean
  /**
   * Sets width to 100%
   */
  wide?: true
  /**
   * Shows a spinner when true
   */
  isLoading?: boolean
}

const Button = ({
  title,
  buttonType = EButtonType.PRIMARY,
  size = EButtonSize.XL,
  icon,
  classes,
  inactive,
  wide,
  isLoading,
  ...props
}: ButtonProps): JSX.Element => {
  return (
    <button
      className={`
      ${classes !== undefined ? classes : ''} 
      ctr-btn ${buttonType} ${size} ${inactive === true ? 'inactive' : ''} ${
        wide === true ? 'wide' : ''
      } ${isLoading === true ? 'disabled' : ''}
      `}
      disabled={isLoading === true}
      {...props}
    >
      {icon} {isLoading === true ? <Preloader fill={colors.main900} /> : title}
    </button>
  )
}

export default Button
