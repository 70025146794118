import type React from 'react'
import '../ApiKeysPageModals.scss'
import OTPCodeInput from '../OTPCodeInput/OTPCodeInput'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { useStore } from 'src/models/Store'
import { useLocation } from 'react-router-dom'
import BaseSideBarComponent from 'src/components/BaseSideBarComponent/BaseSideBarComponent'
import LinkIcon from 'src/assets/images/svg/LinkIcon'

type FormInputs = {
  tfa_code: string
}

const DisableApiKey: React.FC = () => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm<FormInputs>({
    defaultValues: { tfa_code: '' }
  })

  const { apiKeysStore, utilityStore } = useStore()
  const location = useLocation()

  const apiKeyId = location.state.id
  const isApiKeyEnabled: boolean = location.state.is_enabled

  const onSubmit: SubmitHandler<FormInputs> = async ({ tfa_code }) => {
    const response = await apiKeysStore.updateApiKey(apiKeyId, tfa_code, {
      is_enabled: !isApiKeyEnabled
    })
    if (response !== undefined) {
      utilityStore.closeSideBarModal()
    }
  }

  return (
    <BaseSideBarComponent
      headerTitle="Disable API key"
      headerIcon={<LinkIcon />}
      checkTFAEnabled
    >
      <div className="DisableApiKey">
        <OTPCodeInput
          control={control}
          label="Please enter the sign-in 2FA code from your authenticator app"
          onSubmit={handleSubmit(onSubmit)}
          isSubmitting={isSubmitting}
          onCancel={() => {
            utilityStore.closeSideBarModal()
          }}
          noAutoFocus
        />
      </div>
    </BaseSideBarComponent>
  )
}
export default DisableApiKey
