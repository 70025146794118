import type React from 'react'
import '../WithdrawPage.scss'
import Button from 'src/ui/Button'
import { useNavigate, useParams } from 'react-router-dom'

interface WithdrawCompletedProps {
  amount: string
  name: string
  slug: string
}

const WithdrawCompleted: React.FC<WithdrawCompletedProps> = ({
  amount,
  name,
  slug
}) => {
  const navigate = useNavigate()
  const { coinName } = useParams()
  return (
    <>
      <h3>Withdraw request is completed</h3>
      <div className="WithdrawPage-AddressSelect">
        Amount
        <div className="WithdrawPage-AddressSelect__slug">
          {amount} {coinName}
        </div>
      </div>
      <div className="WithdrawPage-AddressSelect">
        {name}
        <div className="WithdrawPage-AddressSelect__slug">{slug}</div>
      </div>
      <Button
        title="Done"
        onClick={() => {
          navigate('/balances/all')
        }}
      />
    </>
  )
}
export default WithdrawCompleted
