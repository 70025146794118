// UseParams does not let explicitly specify types, since the params are passed in the ROUTER definition..
// They cannot be undefiend in ANY circumstances
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type React from 'react'
import '../WithdrawPage.scss'
import { useNavigate, useParams } from 'react-router-dom'
import { useStore } from 'src/models/Store'
import { colors } from 'src/assets/scss/themes'
import InfoIcon from 'src/assets/images/svg/InfoIcon'
import Select from 'src/components/Select/Select'
import Button, { EButtonType } from 'src/ui/Button'
import { type WithdrawFormInputs, type formStateType } from '../WithdrawPage'
import { type Control, Controller, type UseFormSetValue } from 'react-hook-form'
import { observer } from 'mobx-react'
import { useEffect } from 'react'
import Preloader from 'src/ui/Preloader'
import NoTFASideBarComponent from 'src/components/EnableTFASidebarComponent/EnableTFASidebarComponent'
import { type OptionProps, components } from 'react-select'
import WithdrawAddressItem from '../WithdrawAddressItem/WithdrawAddressItem'

interface WithdrawSelectWalletProps {
  setFormStep: formStateType
  formControl: Control<WithdrawFormInputs, any>
  setCurrentWallet: UseFormSetValue<WithdrawFormInputs>
}

const WithdrawSelectWallet: React.FC<WithdrawSelectWalletProps> = observer(
  ({ setFormStep, formControl, setCurrentWallet }) => {
    const { networkId } = useParams()

    const navigate = useNavigate()
    const { initialStore, withdrawAddressesStore, utilityStore, userStore } =
      useStore()

    // Get addresses on first render
    useEffect(() => {
      void withdrawAddressesStore.getWithdrawAddresses({
        network_id: parseInt(networkId!)
      })
    }, [])

    const options = initialStore.networks.items.map((item) => {
      return {
        label: item.name,
        value: item.id
      }
    })
    const defaultSelectValue = options.find(
      (option) => parseInt(networkId!) === option.value
    )

    // React select custom component for icons near options in the dropdown
    const Option = (
      props: OptionProps<{ label: string; value: number }, false>
    ): JSX.Element => {
      const networkImage = `network_${props.data.label}`

      return (
        <components.Option {...props}>
          <img
            src={
              process.env.PUBLIC_URL + `/assets/images/ps/${networkImage}.svg`
            }
            // TODO: Leave alt empty for now, change when most images and networks are aligned on the backend
            alt={''}
          />
          {props.children}
        </components.Option>
      )
    }
    if (!userStore.user.tfa_enabled) return <NoTFASideBarComponent />

    return (
      <>
        <h3>Select wallet for withdraw</h3>
        <div className="WithdrawPage-Warning">
          <InfoIcon fillbackground={colors.error} fillinside={colors.white} />
          <span>
            Please check carefully your network selection. If the top up is
            submitted within the wrong network, the funds will be lost.
          </span>
        </div>
        <Controller
          control={formControl}
          name="network"
          defaultValue={defaultSelectValue}
          render={({ field: { onChange, value } }) => (
            <Select
              options={options}
              defaultValue={value}
              transparent
              components={{ Option }}
              onChange={(item) => {
                onChange(item)
                if (item !== null) {
                  void withdrawAddressesStore.getWithdrawAddresses({
                    network_id: item.value
                  })
                }
              }}
            />
          )}
        />
        <div className="WithdrawPage-ButtonGroup">
          <Button
            title="Add new address"
            onClick={() => {
              navigate('add')
            }}
          />
          <Button
            title="Manage addresses"
            buttonType={EButtonType.SECONDARY}
            disabled={withdrawAddressesStore.withdrawAddresses.length <= 0}
            onClick={() => {
              navigate('manage')
            }}
          />
        </div>
        <div className="WithdrawPage-AddressList">
          {!withdrawAddressesStore.isLoading ? (
            withdrawAddressesStore.withdrawAddresses.map((withdrawAddress) => (
              <WithdrawAddressItem
                key={withdrawAddress.id}
                withdrawAddress={withdrawAddress}
                onClick={() => {
                  setCurrentWallet('selectedWallet', withdrawAddress)
                  setFormStep('enterAmount')
                }}
              />
            ))
          ) : (
            <Preloader />
          )}
          {withdrawAddressesStore.withdrawAddresses.length < 1 && (
            <div className="WithdrawPage-NoAddress">
              No addresses found, please add a new one.
            </div>
          )}
        </div>
      </>
    )
  }
)
export default WithdrawSelectWallet
