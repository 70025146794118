import React, { useState } from 'react'
import './TeamPage.scss'
import BaseDataTable from 'src/components/BaseDataTable/BaseDataTable'
import { type TableColumn } from 'react-data-table-component'
import Button from 'src/ui/Button'
import DotsMenuIcon from 'src/assets/images/svg/DotsMenuIcon'
import { Dropdown } from 'react-bootstrap'
import useSideBarModal from 'src/hooks/useSideBarModal'
import EditUserProfileModal from 'src/components/Modals/TeamPageModals/EditUserProfileModal/EditUserProfileModal'
import UserProfileIcon from 'src/assets/images/svg/UserProfileIcon'
import DeleteUserModal from 'src/components/Modals/TeamPageModals/DeleteUserModal/DeleteUserModal'
import AddNewUserModal from 'src/components/Modals/TeamPageModals/AddNewUserModal/AddNewUserModal'
import { useStore } from 'src/models/Store'
import DotsMenuDropdown from 'src/components/DotsMenuDropdown/DotsMenuDropdown'

interface TeamsPageProps {
  test?: string
}

const columns: Array<TableColumn<(typeof testData)[0]>> = [
  {
    name: 'Action',
    cell: (row) => actionMenuButton(row),
    button: true
  },
  {
    name: 'ID',
    selector: (row) => row.id,
    allowOverflow: false,
    grow: 0,
    center: true,
    style: {
      backgroundColor: '#2E2E2E',
      borderRadius: 10,
      height: 25,
      alignSelf: 'center'
    }
  },
  { name: 'Permissions', selector: (row) => row.permissions },
  {
    name: 'Status',
    selector: (row) => row.status,
    cell: (row, rowIndex, column, id) => (
      <div
        className={`TeamPage-StatusColumn ${
          row.status === 'Active' ? 'Active' : 'Deactivated'
        }`}
      >
        <span>{row.status}</span>
      </div>
    )
  },
  { name: 'Email', selector: (row) => row.email }
]

const actionMenuButton = (userData: (typeof testData)[0]): JSX.Element => {
  const { openModal } = useSideBarModal()
  return (
    <DotsMenuDropdown>
      <Dropdown.Item
        onClick={(e) => {
          console.log(e)
          openModal(
            <EditUserProfileModal userData={userData} />,
            <UserProfileIcon />,
            'Edit user profile'
          )
        }}
      >
        Edit user profile
      </Dropdown.Item>
      <Dropdown.Item>
        {userData.status === 'active' ? 'Deactivate user' : 'Activate user'}
      </Dropdown.Item>
      <Dropdown.Item
        onClick={(e) => {
          console.log(e)
          openModal(
            <DeleteUserModal userData={userData} />,
            <UserProfileIcon />,
            'Delete user'
          )
        }}
      >
        Delete user
      </Dropdown.Item>
    </DotsMenuDropdown>
  )
}

const dotsMenuDropdown = React.forwardRef<
  HTMLDivElement,
  { onClick: React.MouseEventHandler<SVGSVGElement> }
>(({ onClick }, ref) => (
  <div ref={ref}>
    <DotsMenuIcon
      className="TeamPage-Menu"
      onClick={(e) => {
        onClick(e)
      }}
    />
  </div>
))

const testData = [
  { id: 1, permissions: 'Owner', status: 'active', email: 'someMail@dasd.com' },
  {
    id: 323123,
    permissions: 'Owner',
    status: 'Active',
    email: 'someMail@dasd.com'
  },
  {
    id: 9999999,
    permissions: 'limits',
    status: 'Active',
    email: 'drakon@dasd.com'
  },
  {
    id: 8888888888231231,
    permissions: 'view only',
    status: 'Deactivated',
    email: 'ffjeje@dasd.com'
  },
  {
    id: 484842,
    permissions: 'view only',
    status: 'Active',
    email: 'sam33221@dasd.com'
  },
  {
    id: 40,
    permissions: 'Owner',
    status: 'Active',
    email: 'someMail322@dasd.com'
  }
]

const TeamsPage: React.FC<TeamsPageProps> = () => {
  const [filterButtonActive, setFilterButtonActive] = useState<
    'Show All' | 'Owner' | 'Limits' | 'View only'
  >('Show All')
  const store = useStore()
  const { openModal } = useSideBarModal()
  return (
    <>
      <div className="TeamPage">
        <div className="TeamPage-Heading">
          <h3>Team permissions</h3>
          <Button
            inactive={filterButtonActive !== 'Show All'}
            title="Show All"
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              console.log(e)
              // filter logic
              setFilterButtonActive('Show All')
            }}
          />
          <Button
            inactive={filterButtonActive !== 'Owner'}
            title="Owner"
            onClick={() => {
              setFilterButtonActive('Owner')
            }}
          />
          <Button
            inactive={filterButtonActive !== 'Limits'}
            title="Limits"
            onClick={() => {
              setFilterButtonActive('Limits')
              // filter logic here
            }}
          />
          <Button
            inactive={filterButtonActive !== 'View only'}
            title="View only"
            onClick={() => {
              setFilterButtonActive('View only')
              // filter logic here
            }}
          />
        </div>
        <div className="TeamPage-Filters">
          <Button
            title="Add new user"
            onClick={() => {
              openModal(<AddNewUserModal />, <UserProfileIcon />, 'New User')
            }}
          />
        </div>
        {/* // TODO: Leave pagination from reports for now, later add team page pagination */}
        {/* <BaseDataTable */}
        {/*  columns={columns} */}
        {/*  data={testData} */}
        {/*  paginationLogic={store.reportsStore.pagination} */}
        {/* /> */}
      </div>
    </>
  )
}
export default TeamsPage
dotsMenuDropdown.displayName = 'dotsMenuDropdown'
