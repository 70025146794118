import { type ReactElement, type SVGProps } from 'react'

const HamburgerIcon = (
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGElement> => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#F3FF00"
      {...props}
    >
      <path d="M10 12L20 12" strokeWidth="2" strokeLinecap="round" />
      <path d="M6 19L20 19" strokeWidth="2" strokeLinecap="round" />
      <path d="M6 5L20 5" strokeWidth="2" strokeLinecap="round" />
    </svg>
  )
}
export default HamburgerIcon
