import { type SVGProps, type ReactElement } from 'react'

const DotsMenuIcon = (
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGElement> => {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1107_8031)">
        <g clipPath="url(#clip1_1107_8031)">
          <circle cx="7.5" cy="13" r="1.5" />
          <circle cx="12.5" cy="13" r="1.5" />
          <circle cx="17.5" cy="13" r="1.5" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1107_8031">
          <rect
            width="25"
            height="25"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
        <clipPath id="clip1_1107_8031">
          <rect
            width="25"
            height="25"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
export default DotsMenuIcon
