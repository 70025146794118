import type React from 'react'
import './CopyTextComponent.scss'
import Button, { EButtonSize } from '../Button'
import InfoIcon from 'src/assets/images/svg/InfoIcon'
import { colors } from 'src/assets/scss/themes'
import { useState } from 'react'

interface CopyTextComponentProps {
  text: string
}

/**
 * Component for displaying some text and copying it to clipboard
 */
const CopyTextComponent: React.FC<CopyTextComponentProps> = ({ text }) => {
  const [copiedTextToClipboard, setCopiedTextToClipboard] = useState(false)

  const handleCopyText = async (): Promise<void> => {
    setCopiedTextToClipboard(true)
    await navigator.clipboard.writeText(text)
    setTimeout(() => {
      setCopiedTextToClipboard(false)
    }, 2500)
  }

  return (
    <>
      <div className="CopyTextComponent">
        <div className="CopyTextComponent-Content">
          <span>{text}</span>
          <Button title="Copy" size={EButtonSize.SM} onClick={handleCopyText} />
        </div>
        {copiedTextToClipboard && (
          <p className="CopyTextComponent-AddressCopied">
            <InfoIcon fill={colors.success} color={'black'} /> Value has been
            copied
          </p>
        )}
      </div>
    </>
  )
}
export default CopyTextComponent
