import { observer } from 'mobx-react'
import { Dropdown } from 'react-bootstrap'
import type React from 'react'
import type FiltersStore from 'src/models/FiltersStore'

type TDropdownFilter = {
  /**
   * Default name in the filter box that tells what kind of filter it is
   */
  filterName: string
  /**
   * Placeholder when no filter item is selected
   */
  noActiveFilterName: string
  /**
   * Items to be displayed in the dropdown
   */
  filterItems: Array<{ name: string; value: string | boolean }>
  /**
   * Specific filter store
   */
  filtersStore: FiltersStore<any>
  /**
   * Filter name that is used to send to BE, found in config of all FilterStore's
   */
  filterNameFromFilterConfig: string
}

export type DFType = {
  onClick: (v: any) => void
  defaultName: string
  values: any[]
  toggleCaption: string
  setDefault: () => void
}

const DropdownFilter = observer(
  ({ onClick, defaultName, values, toggleCaption, setDefault }: DFType) => {
    return (
      <Dropdown className="btn-group">
        <Dropdown.Toggle
          className={'ctr-btn filter'}
          variant="dark"
          id="statuses"
          size={'sm'}
        >
          {toggleCaption}
        </Dropdown.Toggle>
        <Dropdown.Menu variant={'dark'}>
          <Dropdown.Item
            onClick={() => {
              setDefault()
            }}
          >
            {defaultName}
          </Dropdown.Item>
          {values.map((v) => {
            return (
              <Dropdown.Item
                key={v.name}
                onClick={() => {
                  onClick(v)
                }}
              >
                {v.name}
              </Dropdown.Item>
            )
          })}
        </Dropdown.Menu>
      </Dropdown>
    )
  }
)

export const DropdownFilterNew: React.FC<TDropdownFilter> = observer(
  ({
    filterItems,
    noActiveFilterName,
    filterName,
    filtersStore,
    filterNameFromFilterConfig
  }) => {
    const resetFilter = (): void => {
      filtersStore.setSearchFields([
        {
          field: filterNameFromFilterConfig,
          value: ''
        }
      ])
    }

    const getActiveFilterValue = (): string => {
      const foundValue = filterItems.find(
        (item) =>
          item.value === filtersStore.filters[filterNameFromFilterConfig]
      )
      if (foundValue !== undefined) return foundValue.name

      return noActiveFilterName
    }

    return (
      <Dropdown className="btn-group">
        <Dropdown.Toggle
          className={'ctr-btn filter'}
          variant="dark"
          size={'sm'}
        >
          {`${filterName}: ${getActiveFilterValue()}`}
        </Dropdown.Toggle>
        <Dropdown.Menu variant={'dark'}>
          <Dropdown.Item
            onClick={() => {
              resetFilter()
            }}
          >
            {noActiveFilterName}
          </Dropdown.Item>
          {filterItems.map((v) => {
            return (
              <Dropdown.Item
                key={v.name}
                onClick={() => {
                  filtersStore?.setSearchFields([
                    { field: filterNameFromFilterConfig, value: v.value }
                  ])
                }}
              >
                {v.name}
              </Dropdown.Item>
            )
          })}
        </Dropdown.Menu>
      </Dropdown>
    )
  }
)
export default DropdownFilter
