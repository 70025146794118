import { type SVGProps, type ReactElement } from 'react'

const TrashcanIcon = (
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGElement> => {
  return (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      stroke="white"
      {...props}
    >
      <g id="Group 2538">
        <path
          id="Vector"
          d="M1 5.7857H3.1429M3.1429 5.7857V20.0714C3.1429 21.6494 4.42208 22.9285 6.00003 22.9285H16C17.578 22.9285 18.8571 21.6494 18.8571 20.0714V5.7857M3.1429 5.7857H18.8571M18.8571 5.7857H21M6.71432 1.5H15.2857M8.14284 10.7857V17.9285M13.8572 10.7857V17.9285"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}
export default TrashcanIcon
