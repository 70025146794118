import { observer } from 'mobx-react'
import { Outlet } from 'react-router-dom'
import MenuButton from 'src/ui/Menu/MenuButton'

const AddressesPage = observer(() => {
  return (
    <>
      <div className="ReportsPage">
        <MenuButton link="/addresses" title="System Addresses" end />
        <MenuButton
          link="/addresses/customAddresses"
          title="Custom Addresses"
        />
        <MenuButton link="/addresses/fiat-addresses" title="Bank Accounts" />
      </div>
      <Outlet />
    </>
  )
})

export default AddressesPage
