import type React from 'react'
import '../ApiKeysPageModals.scss'
import BaseSideBarComponent from 'src/components/BaseSideBarComponent/BaseSideBarComponent'
import LinkIcon from 'src/assets/images/svg/LinkIcon'
import { useState } from 'react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { useStore } from 'src/models/Store'
import Button, { EButtonType } from 'src/ui/Button'
import OTPCodeInput from '../OTPCodeInput/OTPCodeInput'
import Input from 'src/ui/Input/Input'
import { type IApiKey } from 'src/models/ApiKeysStore'

type FormInputs = {
  name: string | undefined
  tfa_code: string
  ipsAsString: string
}

const EditApiKey: React.FC = () => {
  const [formStep, setFormStep] = useState<'keyEdit' | '2FA'>('keyEdit')
  const [selectedApiKey, setSelectedApiKey] = useState<IApiKey>()
  const { apiKeysStore, utilityStore } = useStore()
  const location = useLocation()

  const {
    register,
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    trigger
  } = useForm<FormInputs>({
    defaultValues: async () => {
      const response = await apiKeysStore.getSingleApiKey(location.state.id)
      if (response !== undefined) {
        const { name, ips_white_list } = response
        setSelectedApiKey(response)
        return { name, ipsAsString: ips_white_list.toString(), tfa_code: '' }
      }
      return { name: '', ipsAsString: '', tfa_code: '' }
    }
  })

  const ipListPattern = /^(.*((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4},? ?)$/

  const onSubmit: SubmitHandler<FormInputs> = async ({
    name,
    tfa_code,
    ipsAsString
  }) => {
    if (selectedApiKey !== undefined) {
      // Remove white spaces and split it all into an array
      const ips_white_list = ipsAsString.replace(' ', '').split(',')
      const response = await apiKeysStore.updateApiKey(
        selectedApiKey.id,
        tfa_code,
        {
          ips_white_list,
          name
        }
      )
      if (response !== undefined) {
        utilityStore.closeSideBarModal()
      }
    }
  }

  const renderFormStep = (): JSX.Element => {
    if (formStep === 'keyEdit') {
      return (
        <>
          <h2>Manage API key</h2>
          <Input
            placeholder={selectedApiKey?.name}
            label="Name for your new key"
            {...register('name')}
          />
          <Input
            label="Whitelist: Please enter IPs separated by commas"
            {...register('ipsAsString', {
              pattern: {
                value: ipListPattern,
                message:
                  'Please enter a valid list of IP addresses separated by commas'
              }
            })}
          />
          <div className="CreateApiKey-Error">
            {errors.ipsAsString?.message}
          </div>
          <div className="CreateApiKey-ButtonGroup">
            <Button
              title="Save"
              onClick={async () => {
                const isIpListValid = await trigger('ipsAsString', {
                  shouldFocus: true
                })
                if (isIpListValid) {
                  setFormStep('2FA')
                }
              }}
            />
            <Button
              title="Cancel"
              buttonType={EButtonType.SECONDARY}
              onClick={() => {
                utilityStore.closeSideBarModal()
              }}
            />
          </div>
        </>
      )
    }
    if (formStep === '2FA') {
      return (
        <OTPCodeInput
          control={control}
          label="Please enter the sign-in 2FA code from your authenticator app"
          onSubmit={handleSubmit(onSubmit)}
          isSubmitting={isSubmitting}
          onCancel={() => {
            utilityStore.closeSideBarModal()
          }}
        />
      )
    }
    return <div>Something went wrong, please contact support</div>
  }
  return (
    <BaseSideBarComponent
      headerTitle="Edit API key"
      headerIcon={<LinkIcon />}
      checkTFAEnabled
    >
      <div className="EditApiKey">{renderFormStep()}</div>
    </BaseSideBarComponent>
  )
}
export default EditApiKey
