import { observer } from 'mobx-react'
import { useStore } from '../../models/Store'
import Alert from './Alert'
import './Alert.scss'

const AlertsList = observer(() => {
  const { alertsStore } = useStore()
  if (Object.keys(alertsStore.list).length < 1) return null
  return (
    <div className={'alert-container'}>
      {Object.keys(alertsStore.list).map((id) => {
        return <Alert key={id} alert={alertsStore.list[id]} />
      })}
    </div>
  )
})

export default AlertsList
