import type Store from './Store'
import {
  action,
  autorun,
  makeAutoObservable,
  runInAction,
  toJS,
  when
} from 'mobx'
import FiltersStore from './FiltersStore'

export type TTransactionsGetParams = {
  commission_type: 'b2b' | 'c2b'
}

type TFeeCommisions = {
  percent_value: string
  min_amount: string
  absolute_amount: string
  min_amount_coin_name: string
}

type TB2BFees = Record<
  string,
  {
    top_up_deposit: TFeeCommisions
    settlement_withdraw: TFeeCommisions
    exchange_withdraw: TFeeCommisions
  }
>

type TC2BFees = Record<
  string,
  {
    customer_deposit: TFeeCommisions
    customer_deposit_with_exchange: TFeeCommisions
    customer_withdraw: TFeeCommisions
    customer_withdraw_with_exchange: TFeeCommisions
  }
>

type TList = {
  b2b: TB2BFees
  c2b: TC2BFees
}

class FeesStore {
  constructor(private readonly rootStore: Store) {
    this.rootStore = rootStore
    makeAutoObservable<FeesStore, 'rootStore'>(this, {
      rootStore: false,
      getFees: action
    })
    this.filters = new FiltersStore(rootStore, ['commission_type'])
    when(
      () =>
        this.rootStore.authStore.isAuth &&
        !Number.isNaN(this.rootStore.merchantStore.currentMerchant.id),
      () => {
        autorun(() => {
          if (!Number.isNaN(this.rootStore.merchantStore.currentMerchant.id)) {
            void this.getFees({ commission_type: 'b2b' })
            void this.getFees({ commission_type: 'c2b' })
          }
        })
      }
    )
  }

  filters: FiltersStore<['commission_type']>
  list: TList = {
    c2b: {},
    b2b: {}
  }

  isLoading: boolean = false
  isFetched: boolean = false
  loadingError: boolean = false

  async getFees(params: TTransactionsGetParams): Promise<void> {
    const search = { ...params, ...toJS(this.filters.searchString) }
    this.isLoading = true
    const response = await this.rootStore.api.get<{
      items: TB2BFees | TC2BFees
    }>('/jsapi/commissions', {
      ...search
    })

    if (response !== undefined) {
      runInAction(() => {
        if (search.commission_type === 'b2b') {
          this.list.b2b = response.items as TB2BFees
        } else {
          this.list.c2b = response.items as TC2BFees
        }
        this.isFetched = true
        this.isLoading = false
        this.loadingError = false
      })
    }
  }
}

export default FeesStore
