import { observer } from 'mobx-react'
import { Col, Row } from 'react-bootstrap'
import { useStore } from '../../models/Store'
import { NavLink, Outlet } from 'react-router-dom'
import Preloader from '../../ui/Preloader'

const Profile = observer(() => {
  const { userStore } = useStore()
  console.log(userStore.isLoading, 'userStore.isLoading')
  // if (!userStore.isLoading) return <Preloader />
  return (
    <>
      <Row
        style={{
          alignItems: 'center',
          marginBlock: 30
        }}
      >
        <Col xs={'auto'}>
          <div className={'nav-pills'}>
            <NavLink
              to={'info'}
              className={({ isActive }) => (isActive ? 'pill active' : 'pill')}
            >
              Profile
            </NavLink>
            <NavLink
              to={'security'}
              className={({ isActive }) => (isActive ? 'pill active' : 'pill')}
            >
              Security
            </NavLink>
          </div>
        </Col>
      </Row>
      <Outlet />
    </>
  )
})

export default Profile
