import { makeAutoObservable } from 'mobx'
import type Store from './Store'
import { EAlertTypes } from './AlertsStore'

/**
 * Layout store handles miscellaneous states, such as modal state, popup state etc
 */
class UtilityStore {
  sideBarModalOpen: boolean = false

  constructor(private readonly rootStore: Store) {
    this.rootStore = rootStore
    makeAutoObservable<UtilityStore, 'rootStore'>(this, {
      rootStore: false
    })
  }

  /**
   * Checks for TFA on the account and if not returns false with an error notification
   */
  public isTFAEnabledWithError(): boolean {
    if (!this.rootStore.userStore.user.tfa_enabled) {
      this.rootStore.alertsStore.addAlert({
        id: 'apiKeys-no-tfa',
        title: 'Error',
        content:
          'You need to enable two factor authentication for this functionality!',
        type: EAlertTypes.ERROR,
        timeout: 3000
      })
      return false
    }
    return true
  }

  public closeSideBarModal(): void {
    this.sideBarModalOpen = false
  }

  public openSideBarModal(): void {
    this.sideBarModalOpen = true
  }
}

export default UtilityStore
