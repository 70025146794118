import type React from 'react'
import './CreateTransactionReport.scss'
import Datepicker from 'src/ui/Datepicker/Datepicker'
import Button from 'src/ui/Button'
import { useStore } from 'src/models/Store'
import useSideBarModal from 'src/hooks/useSideBarModal'
import { type SubmitHandler, useForm, Controller } from 'react-hook-form'

type FormInputs = {
  selectedDates: [Date, Date | null]
}

const CreateTransactionReport: React.FC = () => {
  const { reportsStore } = useStore()
  const { closeModal } = useSideBarModal()
  const {
    handleSubmit,
    control,
    formState: { isSubmitting }
  } = useForm<FormInputs>({
    defaultValues: { selectedDates: [new Date(), null] }
  })

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    const response = await reportsStore.createReport(
      'transactions',
      data.selectedDates[0],
      data.selectedDates[1]
    )

    if (response !== undefined) {
      closeModal()
    }
  }

  return (
    <div className="CreateTransactionReport">
      <h2>Create report</h2>
      <p>Select period</p>
      <Controller
        name="selectedDates"
        control={control}
        render={({ field: { onChange } }) => (
          <Datepicker onDateChange={onChange} />
        )}
      />
      <Button
        title="Create report"
        onClick={handleSubmit(onSubmit)}
        isLoading={isSubmitting}
      />
    </div>
  )
}
export default CreateTransactionReport
