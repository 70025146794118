import { observer } from 'mobx-react'
import { NavLink } from 'react-router-dom'

const FeesFilters = observer(() => {
  return (
    <>
      <div className={'nav-pills'}>
        <NavLink
          to={'c2b'}
          className={({ isActive }) => (isActive ? 'pill active' : 'pill')}
        >
          C2B
        </NavLink>
        <NavLink
          to={'b2b'}
          className={({ isActive }) => (isActive ? 'pill active' : 'pill')}
        >
          B2B
        </NavLink>
      </div>
    </>
  )
})

export default FeesFilters
