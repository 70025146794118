import type React from 'react'
import './AddNewUserModal.scss'
import Select from 'react-select'
import { useState } from 'react'
import Button from 'src/ui/Button'
import useSideBarModal from 'src/hooks/useSideBarModal'
import { type SubmitHandler, useForm } from 'react-hook-form'

interface AddNewUserModalProps {
  test?: string
}

interface FormInputs {
  email: string
  permission: { label: string; value: string }
}

const AddNewUserModal: React.FC<AddNewUserModalProps> = ({ test }) => {
  const selectOptions = [
    { label: 'View Only', value: 'View Only' },
    { label: 'Owner', value: 'Owner' },
    { label: 'Limits', value: 'Limits' }
  ]
  const [chosenPermission, setChosenPermission] = useState<
    (typeof selectOptions)[0]
  >(selectOptions[0])

  const { closeModal } = useSideBarModal()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormInputs>()

  const emailRegex = /^\S+@\S+\.\S+$/

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    console.log(data)
    console.log(chosenPermission.value)

    // Send data to server here, if error return a toast notification with server response
    // Use submit data object and chosenPermission state
  }
  return (
    <div className="AddNewUserModal">
      Email
      <input
        className="AddNewUserModal-UserEmail"
        {...register('email', { pattern: emailRegex, required: true })}
      />
      {errors.email != null && <span>A valid email address is required</span>}
      Permissions
      {/* // Put react select in its own component later */}
      <Select
        defaultValue={selectOptions[0]}
        onChange={(e) => {
          if (e !== null) {
            setChosenPermission(e)
          }
        }}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            height: 60,
            backgroundColor: '#000',
            borderColor: state.isFocused ? '#F3FF00' : '#000',
            ':hover': {
              borderColor: '#F3FF00',
              cursor: 'pointer'
            }
          }),
          dropdownIndicator: (baseStyles, state) => ({
            ...baseStyles,
            color: state.isFocused ? '#F3FF00' : '',
            ':hover': {
              color: '#F3FF00'
            }
          }),
          menu: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: '#474747',
            ':hover': {}
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            margin: 10,
            backgroundColor: '#000',
            border: state.isSelected ? '1px solid #F3FF00' : '1px solid #000',
            width: 'initial',
            borderRadius: 10,
            paddingBlock: 5,

            ':hover': {
              color: '#F3FF00',
              cursor: 'pointer'
            }
          }),
          input: (baseStyles) => ({
            ...baseStyles,
            color: '#FFF'
          }),
          singleValue: (baseStyles) => ({
            ...baseStyles,
            color: '#FFF'
          })
        }}
        options={selectOptions}
      />
      <div className="AddNewUserModal-ButtonGroup">
        <Button onClick={handleSubmit(onSubmit)} title="Confirm" />
        <Button onClick={closeModal} title="Cancel" classes="SecondaryButton" />
      </div>
    </div>
  )
}
export default AddNewUserModal
