import { type SVGProps, type ReactElement } from 'react'

const WithdrawIcon = (
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGElement> => {
  return (
    <svg
      width="10"
      height="20"
      viewBox="0 0 10 15"
      fill="white"
      stroke="white"
      {...props}
    >
      <g id="payout">
        <path
          id="Line 31"
          d="M4.64645 0.146447C4.84171 -0.0488156 5.15829 -0.0488155 5.35355 0.146447L8.53553 3.32843C8.7308 3.52369 8.7308 3.84027 8.53553 4.03553C8.34027 4.2308 8.02369 4.2308 7.82843 4.03553L5 1.20711L2.17157 4.03553C1.97631 4.2308 1.65973 4.2308 1.46447 4.03553C1.2692 3.84027 1.2692 3.52369 1.46447 3.32843L4.64645 0.146447ZM5.5 11.5C5.5 11.7761 5.27614 12 5 12C4.72386 12 4.5 11.7761 4.5 11.5L5.5 11.5ZM5.5 0.5L5.5 11.5L4.5 11.5L4.5 0.5L5.5 0.5Z"
        />
        <path id="Line 75" d="M1 14H9" strokeLinecap="round" />
      </g>
    </svg>
  )
}
export default WithdrawIcon
