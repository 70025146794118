import { Outlet } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { useStore } from '../../models/Store'

const TransactionsLayout = observer(() => {
  const store = useStore()
  useEffect(() => {
    store.transactionStore.subscribe()
    return () => {
      store.transactionStore.unsubscribe()
    }
  }, [])
  return <Outlet />
})

export default TransactionsLayout
