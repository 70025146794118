import type React from 'react'
import './WithdrawAddressItem.scss'
import { type TWithdrawAddress } from 'src/models/WithdrawAddressesStore'
import { type ComponentPropsWithoutRef } from 'react'
import { observer } from 'mobx-react'

interface WithdrawAddressItemProps extends ComponentPropsWithoutRef<'div'> {
  withdrawAddress: TWithdrawAddress
}

const WithdrawAddressItem: React.FC<WithdrawAddressItemProps> = observer(
  ({ withdrawAddress, ...props }) => {
    return (
      <div className="WithdrawAddressItem" {...props}>
        <img
          src={
            process.env.PUBLIC_URL +
            `/assets/images/ps/network_${withdrawAddress.network_name}.svg`
          }
          // TODO: Leave alt empty for now, change when most images and networks are aligned on the backend
          alt={''}
        />
        <div className="WithdrawAddressItem-Item">
          {withdrawAddress.name}
          <div className="WithdrawAddressItem__slug">
            {withdrawAddress.slug}
          </div>
        </div>
      </div>
    )
  }
)
export default WithdrawAddressItem
